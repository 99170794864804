@if(siteResolver.siteModel)
{
  <h1 style="cursor: pointer; display: flex; align-items: center;" (click)="goBack()">
    <i class="pi pi-angle-left mr16"></i>
    <div class="mr16">{{siteResolver.siteModel.name}}</div>

    @if(siteResolver.siteModel.data.onNeviRoute)
    {
      <div style="width: 100px; text-align: center; font-size: 12px; text-transform: capitalize; background: #D3F26A; color: #226092; padding: 4px 8px; border-radius: 8px;">
        Nevi
      </div>
    }

  </h1>
}
@else
{
  <h1 style="cursor: pointer;" (click)="goBack()">
    <i class="pi pi-angle-left"></i>
    {{'@@SITE.PAGE.TITLE' | translate}}
  </h1>
}

<div class="just-flex mt64">
  <div
    [ngClass] = "wizzardIndex == 1 ? 'step-active' : 'step-idel' "
    (click)   = "processStep(1)">
    <div class="step-id mr16">1</div>
    <div class="step-title">{{'@@SITE.ACTION.WIZZARD_SITE_DETAILS' | translate}}</div>
  </div>

  <div class="separator"></div>

  <div
    [ngClass] = "wizzardIndex == 2 ? 'step-active' : 'step-idel' "
    (click)   = "processStep(2)">
    <div class="step-id mr16">2</div>
    <div class="step-title">{{'@@SITE.ACTION.WIZZARD_SITE_CONFIG' | translate}}</div>
  </div>

  <div class="separator"></div>

  <div
    [ngClass]="wizzardIndex == 3 ? 'step-active' : 'step-idel'"
    (click)="processStep(3)">
    <div class="step-id mr16">3</div>
    <div class="step-title">
      {{'@@SITE.ACTION.WIZZARD_SITE_EXTENDED_DETAILS' | translate}}
    </div>
  </div>

  <div class="separator"></div>

  <div
    [ngClass] = "$$ui.state.isReportAvailable ? 'report-active' : 'step-idel' "
    (click)   = "downloadReport()">
    <div style="    border: solid #dedede 1px; padding: 8px; padding-left: 24px; padding-right: 24px;" class="step-title">
      <i class="pi pi-file-pdf mr16"></i>
      {{'@@SITE.ACTION.WIZZARD_DOWNLOAD_REPORT' | translate}}
    </div>
  </div>

</div>

<div class="flex">

  <div class="mt32 mr32 p16 pl32 col2 bg-border">

      <div class="wizzard mt32 pr32 pb32">

        <wizzard-site-details
          [hidden]                      = "!(wizzardIndex == 1)"
          [isVisible]                   = "wizzardIndex == 1"
          [inputForm]                   = "$parentForm"
          [inputParentForm]             = "getFormArray()">
        </wizzard-site-details>

        <wizzard-site-config
          [hidden]                      = "!(wizzardIndex == 2)"
          [isVisible]                   = "wizzardIndex == 2"
          [inputForm]                   = "$parentForm"
          [inputParentForm]             = "getFormArray()"
          (onUtilityDetailsCalculated)  = "processOnUtilityDetailsCalculated()"
          (onPricingDetailsCalculated)  = "processOnPricingDetailsCalculated()">
        </wizzard-site-config>

        <wizzard-site-extended
          [hidden]="!(wizzardIndex == 3)"
          [isVisible]="wizzardIndex == 3"
          [inputForm]="$parentForm"
          [inputParentForm]="getFormArray()"
          [inputData]="$formArrayMap"
          [inputUiState]="$$ui"
          (onUtilityDetailsCalculated)="processOnUtilityDetailsCalculated()"
          (onPricingDetailsCalculated)="processOnPricingDetailsCalculated()">
        </wizzard-site-extended>

        <div class="just-flex mt32">

          @if(wizzardIndex > 1)
          {
            <div (click)="processPrev()" class="btn btn-plane mr16">
              <i class="pi pi-angle-double-left"></i> {{'@@SITE.ACTION.WIZZARD_GO_BACK' | translate}}
            </div>
            <div class="mr16" style="border-right: solid 1px #dedede;"></div>
          }

          @if(!(wizzardIndex == 3)){
            <div (click)="processSaveAction()" class="btn btn-plane mr16">
              {{'@@SITE.ACTION.WIZZARD_SAVE' | translate}}
            </div>
          }

          @if(wizzardIndex == 1)
          {
            <div (click)="processNext()" class="btn btn-primary mr16">
              {{'@@SITE.ACTION.WIZZARD_CONTINUE' | translate}} <i class="pi pi-angle-double-right"></i>
            </div>
          }

          @if(wizzardIndex == 2)
          {
            <div (click)="processNext()" class="btn btn-primary mr16">
              {{'@@SITE.ACTION.WIZZARD_SCORE' | translate}} <i class="pi pi-angle-double-right"></i>
            </div>

            <!-- <div (click)="processScore(true)" class="btn btn-primary mr16">
              Score with Nodes <i class="pi pi-angle-double-right"></i>
            </div> -->
          }

        </div>
      </div>
  </div>

  @if(wizzardIndex != 3)
  {
    <div class="mt32 bg-border col">
      
      <template-roi
        [inputData]       = "$formArrayMap"
        [inputIsVisible]  = "$$ui.state.isRoiPreviewVisible"
        [inputIsVisibleSuggested]  = "true">
      </template-roi>
      
      <template-cost-operation-mini
        [inputData]       = "$formArrayMap"
        [inputIsVisible]  = "$$ui.state.isCostOperationPreviewVisible">
      </template-cost-operation-mini>

      <template-electricity-price
        [inputData]       = "$formArrayMap"
        [inputIsVisible]  = "$$ui.state.isUtilityDetailsPreviewVisible">
      </template-electricity-price>

      <template-pricing-details
        [inputData]       = "$formArrayMap"
        [inputIsVisible]  = "$$ui.state.isPricingDetailsPreviewVisible">
      </template-pricing-details>

      <template-demand
        [inputData]       = "$formArrayMap"
        [inputIsVisible]  = "$$ui.state.isDemandDetailsPreviewVisible">
      </template-demand>

      <template-roi-report
        [inputData]       = "$formArrayMap"
        [inputIsVisible]  = "$$ui.state.isRoiPreviewVisible"
        [inputIsVisibleSuggested]  = "true">
      </template-roi-report>

    </div>
  }
</div>


<!-- Reports -->
<sidebar-manage-reports
  [(inputIsVisible)]    = "$$ui.state.isSidebarManageReportsVisible"
  [inputData]           = "$$ui.data.sidebarTransactionObject"
  (onReturnObject)      = "processDownloadReport($event)">
</sidebar-manage-reports>
