import {  Component                     ,
          Input                         } from "@angular/core";
import {  CommonModule                  } from "@angular/common";
import {  TranslatePipe                 } from "nv@services/translate.service";
import { TransfromToYearMonthLabelPipe  } from "nv@pipes/year-month-label.pipe";
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector    : 'template-bottom-summary',
  templateUrl : './template-bottom-summary.component.html',
  styleUrl    : './template-bottom-summary.component.scss',
  imports: [
    CommonModule,
    TooltipModule,
    TranslatePipe,
    TransfromToYearMonthLabelPipe
]
})
export class TemplateBottomSummary {

  @Input() public inputData!: any;

  public getScoreData(): any {
      // Grab each field or fallback to 0
        const market     = this.inputData?.marketPotentialScore ?? 0;
        const infra      = this.inputData?.chargingInfrastructureScore ?? 0;
        const financial  = this.inputData?.financialUtilizationPerformanceScore ?? 0;
        const nevi       = this.inputData?.neviScore ?? 0;

        // return [
        //     { title: 'Market',         value: market     },
        //     { title: 'Infrastructure', value: infra      },
        //     { title: 'Financial',      value: financial  },
        //     { title: 'NEVI',           value: nevi       }
        // ];

        return [
            { title: 'Market',         value: market     },
            { title: 'Competition', value: infra      },
            { title: 'Financial',      value: financial + nevi  }
        ];
    }

    public getTrafficPercentage() {
    // Fallback to 0 if the value is missing
        // const total = this.inputData?.avgHandledWeeklyVisits ?? 0;

        // Individual categories
        const local    = this.inputData?.roiAvgWeeklyVisits       ?? 0;
        const miles30  = this.inputData?.trafficThirtyMiles       ?? 0;
        const miles70  = this.inputData?.trafficSeventyMiles      ?? 0;
        const miles70p = this.inputData?.trafficAboveSeventy ?? 0;
        
        const total = local + miles30 + miles70 + miles70p

        // Avoid division by zero
        if (total <= 0) {
            return {
            local       : '0%',
            miles30     : '0%',
            miles70     : '0%',
            miles70plus : '0%'
            };
        }

        // Calculate each category as a fraction of total, then convert to %
        const miles30Pct     = (miles30  / total) * 100;
        const miles70Pct     = (miles70  / total) * 100;
        const miles70plusPct = (miles70p / total) * 100;
        const localPct       = 100 - (miles30Pct + miles70Pct + miles70plusPct);

        return {
            local       : localPct.toFixed(1) + '%',
            miles30     : miles30Pct.toFixed(1) + '%',
            miles70     : miles70Pct.toFixed(1) + '%',
            miles70plus : miles70plusPct.toFixed(1) + '%'
        };
    }

    
    public getMaxValue(index: number): number {
        // const maxValues = [25, 20, 45, 10];
        const maxValues = [25, 20, 55];
        return maxValues[index] ?? 100;
    }

    /**
    * @author Samuil Dobrinski
    * @returns
    */
    public getBarColor(index: number): string {
        const colors = ["#C6213E", "#6D9953", "#22689A", "#D3F26A"];
        return colors[index] || "#CCCCCC"; // Default color if index is out of range
    }
}
