import {  Component                   ,
          inject                      ,
          OnInit                      } from '@angular/core';
import {  CommonModule                } from '@angular/common';
import {  RouterOutlet                } from '@angular/router';
import {  DialogService               ,
          DynamicDialogModule         } from 'primeng/dynamicdialog';
import {  DialogModule                } from 'primeng/dialog';

import {  AppLoaderSpinnerComponent   } from 'nv@components/app-loader-spinner/app-loader-spinner.component';
import {  TranslateService            } from 'nv@services/translate.service';
import {  GlobalRedirectService       } from './@routes/redirect.service';

@Component({
  selector    : 'app-root',
  templateUrl : './app.component.html',
  styleUrls   : ['./app.component.scss'],
  providers   : [DialogService],
  imports     : [
    CommonModule              ,
    RouterOutlet              ,
    AppLoaderSpinnerComponent ,
    DialogModule              ,
    DynamicDialogModule       ,
  ],
})
export class AppComponent implements OnInit {

  private globalRedirect    = inject(GlobalRedirectService);
  private translateService  = inject(TranslateService);

  public ngOnInit(): void {
    this.translateService.loadTranslations('en');
  }

  public goHome() {
    this.globalRedirect.inContextOfCustomer().viewAllCustomers();
  }
}
