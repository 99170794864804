<div class="tac">
  <div class="mt64">
    <img src="/assets/images/logo.png">
    <div class="fsb32 mt8"> {{ '@@404.PAGE.TITLE' | translate }} </div>
    <div class="fs16 mt16"> {{ '@@404.PAGE.SUBTITLE' | translate }}
      <a style="font-weight: bold;" href="dashboard">HOME</a>
    </div>
  </div>
</div>

