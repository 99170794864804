import { inject, Injectable   } from '@angular/core';
import { Pipe, PipeTransform  } from '@angular/core';

import EnJSON                   from 'assets/i18n/en.json';

@Pipe({
  name        : 'translate' ,
  pure        : false
})
export class TranslatePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  public transform(key: string, params?: any): string {
    return this.translateService.translate(key);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TranslateService {

  private translations: any = {};
  private currentLang: string = 'en';

  /**
   * @author Mihail Petrov
   * @param lang
   */
  public loadTranslations(lang: string): void {

    this.translations[lang] = EnJSON;
    this.currentLang        = lang;
  }

  /**
   * @author Mihail Petrov
   * @param key
   * @returns
   */
  public translate(key: string): string {

    const keyCollection = key.split('.');
    let resultValue     = null;

    for(const elementKey of keyCollection) {

      if(resultValue) {
        resultValue = resultValue[elementKey]
      } else {
        resultValue = this.translations[this.currentLang][elementKey]
      }
    }

    return resultValue;
  }

  /**
   * @author Mihail Petrov
   * @param lang
   */
  public switchLanguage(lang: string): void {

    if (!this.translations[lang]) {
      this.loadTranslations(lang);
    } else {
      this.currentLang = lang;
    }
  }
}
