<div class="flex" style="width: 100%;">

  <!-- Traffic Card -->
  <div class="box">
    <div class="icon mb10">
      <i class="pi pi-car"></i>
      <div class="title">Traffic for this location</div>
    </div>

    <div class="flex mt6">
      <div class="title" pTooltip="potential drivers">TAM</div>
      <strong>{{ inputData?.roiAvgWeeklyVisits + inputData?.trafficThirtyMiles + inputData?.trafficSeventyMiles + inputData?.trafficAboveSeventy}}</strong>
    </div>

    <div class="flex mt6">
      <div class="title" pTooltip="% of potential drivers handled by the site">% of TAM served</div>
      <strong>{{ inputData?.utilization }}%</strong>
    </div>

    <div class="flex mt6">
      <div class="title" pTooltip="number of local and travel visitors, limited by either utilization or number of ports">Handled weekly visitors </div>
      <strong>{{ inputData?.avgHandledWeeklyVisits }}</strong>
    </div>

    <div class="flex mt6">
      <div class="title" pTooltip="% of visitors to the site coming from within 20 miles">Local Traffic</div>
      <strong>{{ getTrafficPercentage().local }}</strong>
    </div>

    <div class="flex mt6">
      <div class="title">Travel traffic: 20-30 Miles</div>
      <strong>{{ getTrafficPercentage().miles30 }}</strong>
    </div>

    <div class="flex mt6">
      <div class="title">Travel traffic: 30-70 Miles</div>
      <strong>{{ getTrafficPercentage().miles70 }}</strong>
    </div>

    <div class="flex mt6">
      <div class="title">Travel traffic: &gt;70 Miles</div>
      <strong>{{ getTrafficPercentage().miles70plus }}</strong>
    </div>
  </div>

  <!-- Score Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-car"></i>
      <div class="title">Score for this location: {{ inputData.score }}</div>
    </div>

    @for (distribution of getScoreData(); track $index){
      <div class="flex mt8" style="align-items: center;">
        <div class="title" style="width:200px;">{{distribution.title}}</div>
        <div class="progress-bar">
            <div class="progress-fill"
                [style.width.%]="(distribution.value / getMaxValue($index)) * 100"
                [ngStyle]="{'background-color': getBarColor($index)}">
            </div>
        </div>
      </div>
    }
  </div>

  <!-- Demand Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-dollar"></i>
      <div class="title">Demand Cost</div>
    </div>

    <!--  -->
    <div class="flex mt8">
      <div class="title">Avg. demand cost per kW</div>
      <strong style="color: #d91c5c;">{{inputData?.yearlyAverageRate | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.DEMAND_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData?.costDemand | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Demand cost per kWh</div>
      <strong style="color: #d91c5c;">{{inputData?.estimatedDemandCost | currency:'USD'}}</strong>
    </div>
  </div>

  <!-- Electricity Price Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-bolt"></i>
      <div class="title">Electricity Cost for {{inputData?.utilityPlanType | uppercase}}</div>
    </div>

    @if(inputData?.utilityPlanType == 'fixed'){
      <!--  -->
      <div class="flex mt8">
        <div class="title">Fixed cost (yearly)</div>
        <strong>{{inputData?.fixedMonthlyCost | currency:'USD':'symbol':'1.0-0'}}</strong>
      </div>

      <div class="flex mt8">
        <div class="title">Fixed price</div>
        <strong>{{inputData?.electricityCost | currency:'USD'}}</strong>
      </div>
    }

    @if(inputData?.utilityPlanType == 'tou'){
      <!--  -->
      <div class="flex mt8">
        <div class="title">Fixed cost (yearly)</div>
        <strong>{{inputData?.fixedMonthlyCost | currency:'USD':'symbol':'1.0-0'}}</strong>
      </div>

      <div class="flex mt8">
        <div class="title">On peak</div>
        <strong>{{inputData?.electricityCostPeak | currency:'USD'}}</strong>
      </div>

      <div class="flex mt8">
        <div class="title">Off peak</div>
        <strong>{{inputData?.electricityCostOffPeak | currency:'USD'}}</strong>
      </div>

      <div class="flex mt8">
        <div class="title">Super off peak</div>
        <strong>{{inputData?.electricityCostSuperOffPeak | currency:'USD'}}</strong>
      </div>
    }
  </div>

  <!-- Pricing Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-bolt"></i>
      @if(!(inputData?.hasDriverPriceMode)){
        <div class="title">Driver price for {{ inputData?.driverPriceMultiplier ? "multiplier" : ""}}</div>
      }

      @if(inputData?.hasDriverPriceMode){
        <div class="title">Driver fixed sales price</div>
      }
    </div>

    @if(inputData?.utilityPlanType == 'fixed'){
      <div class="flex mt8">
        <div class="title">Driver price</div>
        <strong>{{inputData?.driverPrice | currency:'USD'}}</strong>
      </div>
    }

    @if(inputData?.utilityPlanType == 'tou'){
      @if(inputData?.hasDriverPriceMode){
        <div class="flex mt8">
          <div class="title">Driver price</div>
          <strong>{{inputData?.driverPricePeak | currency:'USD'}}</strong>
        </div>
      }

      @if(!(inputData?.hasDriverPriceMode)){
        <div class="flex mt8">
          <div class="title">On peak</div>
          <strong>{{inputData?.driverPricePeak | currency:'USD'}}</strong>
        </div>

        <div class="flex mt8">
          <div class="title">Off peak</div>
          <strong>{{inputData?.driverPriceOffPeak | currency:'USD'}}</strong>
        </div>

        <div class="flex mt8">
          <div class="title">Super off peak</div>
          <strong>{{inputData?.driverPriceSuperOffPeak | currency:'USD'}}</strong>
        </div>
      }
    }
  </div>
</div>
