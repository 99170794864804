import { Component     ,
         EventEmitter  ,
         Input         ,
         Output        } from "@angular/core";

@Component({
  selector    : '',
  template    : '',
  styleUrl    : './cc-sidebar.component.scss',
})
export class CcBaseSidebar {


  @Input() public inputTitle          = '[No Title]';
  @Input() public inputSidebarAction  = '';
  @Input() public inputCloseOnByClick = false;

  @Input() public inputIsVisible      = false;
  @Input() public inputData: any

  @Output() public inputIsVisibleChange = new EventEmitter<any>();
  @Output() public onHide               = new EventEmitter<any>();
  @Output() public onReturnObject       = new EventEmitter<any>();


  protected returnToParent($object: any) {

    this.onReturnObject.emit($object);
    this.processHideSidebar()
  }

  /**
   * @author Mihail Petrov
   */
  protected processHideSidebar() {

    this.inputIsVisible = false;
    this.inputIsVisibleChange.emit(this.inputIsVisible);

    this.onHide.emit()
  }

  protected setSidebarTitle(title: string) {
    this.inputTitle = title;
  }

  protected isInEditMode() {
    return this.inputSidebarAction == 'edit';
  }

  protected isInCreateMode() {
    return this.inputSidebarAction == 'create';
  }

  protected isInDeleteMode() {
    return this.inputSidebarAction == 'delete';
  }

  protected isInImportMode() {
    return this.inputSidebarAction == 'import';
  }

  protected isInCreateEditMode() {
    return this.isInCreateMode() || this.isInEditMode();
  }
}
