import { Injectable         } from "@angular/core";
import { Observable         } from "rxjs";
import { ApiResponseModel   ,
         HttpApiService     } from "nv@services/http.service";

@Injectable({
  providedIn: 'root'
})
export class RoiApi extends HttpApiService {

  /**
   * @author Mihail Petrov
   * Register new patient
   * @param $object
   * @returns
   */
  // { address: string | null, city: string | null, zip: string | null }
  public getGeocodeData($object: any): Observable<ApiResponseModel> {

    return this.forEndpoint(`roi`, `geocode`)
               .withBody($object)
               .post()
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public getUtilityData($object: any): Observable<ApiResponseModel>  {

    return this.forEndpoint(`roi`, `utilities`)
               .withBody($object)
               .post()
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public getDemandAverage($object: any): Observable<ApiResponseModel>  {

    return this.forEndpoint(`roi`, `demand_average`)
               .withBody($object)
               .post()
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public getRatePlans($object: any): Observable<ApiResponseModel>  {

    return this.forEndpoint(`roi`, `plans`)
               .withBody($object)
               .post()
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public getPredictedEvRegistration($object: any): Observable<ApiResponseModel>  {

    return this.forEndpoint(`roi`, `predict`)
               .withBody($object)
               .post()
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public getReturnOfInvestment($object: any): Observable<ApiResponseModel>  {

    return this.forEndpoint(`roi`, `roi`)
               .withBody($object)
               .post()
  }
}
