<div class="flex mt32" style="width: 100%;">
    <template-top-summary
    [style]     = "{'width': '100%'}"
    [inputData] = "inputData">
    </template-top-summary>
</div>

<template-competition
    [inputData]="inputData"
    [inputIsVisible]="inputUiState?.state?.isRoiPreviewVisible"
></template-competition>

<div class="flex mt32" style="width: 100%;">
    <template-bottom-summary
    [style]     = "{'width': '100%'}"
    [inputData] = "inputData">
    </template-bottom-summary>
</div>