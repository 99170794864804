import {  Component                     ,
          Input                         } from "@angular/core";
import {  CommonModule                  } from "@angular/common";
import {  TranslatePipe                 } from "nv@services/translate.service";
import { TransfromToYearMonthLabelPipe  } from "nv@pipes/year-month-label.pipe";

@Component({
  selector    : 'template-top-summary',
  templateUrl : './template-top-summary.component.html',
  styleUrl    : './template-top-summary.component.scss',
  imports: [
    CommonModule,
    TranslatePipe,
    TransfromToYearMonthLabelPipe
]
})
export class TemplateTopSummary {

  @Input() public inputData!: any;
}
