@if(inputIsVisible)
{
  <div class="card-box">

    <div>
      <i class="pi pi-dollar fs32 m5"></i>
    </div>

    <div class="ml16">
      
      @if(!(inputData?.hasDriverPriceMode))
      {
        <div class="just-flex" style="align-items: center;">
          <div style="font-weight: bold;" class="mr16">Driver price for </div>

          <div class="fsb18 pill">
            {{ inputData?.driverPriceMultiplier }}
          </div>
          <div style="font-weight: bold;">multiplier</div>
        </div>
      }

      @if(inputData?.hasDriverPriceMode)
      {
        <div class="just-flex" style="align-items: center;">
          <div style="font-weight: bold;" class="mr16">Driver fixed sale price</div>
        </div>
      }

      <div>
        @if(inputData?.utilityPlanType == 'fixed')
        {
          <div class="just-flex mt8" style="align-items: center;">
            <div style="width: 126px;">Driver price</div>
            <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
            class="pi pi-angle-double-right"></i>
            <div class="pill" style="font-size: 18px;">{{inputData?.driverPrice | currency:'USD'}}</div>
          </div>
        }

        @if(inputData?.utilityPlanType == 'tou')
        {
          @if(inputData?.hasDriverPriceMode){
            <div class="just-flex mt8" style="align-items: center;">
              <div style="width: 126px;">Driver price</div>
              <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
              class="pi pi-angle-double-right"></i>
              <div class="pill pill--green" style="font-size: 18px;">{{inputData?.driverPricePeak | currency:'USD'}}</div>
            </div>
          }

          @if(!(inputData?.hasDriverPriceMode)){
            <div class="just-flex mt8" style="align-items: center;">
              <div style="width: 126px;">On peak</div>
              <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
              class="pi pi-angle-double-right"></i>
              <div class="pill pill--green" style="font-size: 18px;">{{inputData?.driverPricePeak | currency:'USD'}}</div>
            </div>

            <div class="just-flex mt8" style="align-items: center;">
              <div style="width: 126px;">Off peak</div>
              <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
              class="pi pi-angle-double-right"></i>
              <div class="pill" style="font-size: 18px;">{{inputData?.driverPriceOffPeak | currency:'USD'}}</div>
            </div>

            <div class="just-flex mt8" style="align-items: center;">
              <div style="width: 126px;">Super off peak</div>
              <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
              class="pi pi-angle-double-right"></i>
              <div class="pill pill--red" style="font-size: 18px;">{{inputData?.driverPriceSuperOffPeak | currency:'USD'}}</div>
            </div>
          }
          
        }

      </div>
    </div>
  </div>
}
@else
{
  <div class="card-box">
    <div>
      <i class="pi pi-dollar fs32 m5"></i>
    </div>
    <div class="ml16">
      <div class="just-flex" style="align-items: center;">
        <div style="font-weight: bold;" class="mr16">Driver fixed sale price</div>
      </div>
      <div class="just-flex mt8" style="align-items: center;">
        <div style="width: 126px;">Driver price</div>
        <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
        class="pi pi-angle-double-right"></i>
        <div class="pill pill--green" style="font-size: 18px;">{{0.45 | currency:'USD'}}</div>
      </div>
    </div>
  </div>
}

