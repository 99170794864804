import {  CommonModule              } from "@angular/common";
import {  Component, inject         } from "@angular/core";
import {  FormControl               ,
          ReactiveFormsModule       ,
          Validators                } from "@angular/forms";
import {  SidebarModule             } from 'primeng/sidebar';
import {  DropdownModule            } from "primeng/dropdown";

import {  CcFormGroup               } from "app/@shared/forms/form-group/cc-form-group.extention";
import {  CountryCollectionService  } from "app/@shared/collections/country.collection.service";

import {  CcFormError               } from "nv@components/cc-form-error/cc-form-error.component";
import {  CcBaseSidebar             } from "nv@components/cc-sidebar/cc-sidebar.base";
import {  CcSidebar                 } from "nv@components/cc-sidebar/cc-sidebar.component";
import {  TranslatePipe             ,
          TranslateService          } from "nv@services/translate.service";
import {  OnlyNumber                } from "app/@shared/directives/only-numbers.directive";

import {  TemplateImport            } from "./@templates/template-import/template-import.component";
import {  TemplateDelete            } from "./@templates/template-delete/template-delete.component";

@Component({
  selector    : 'sidebar-manage-contacts',
  templateUrl : './manage-contacts.component.html',
  styleUrl    : './manage-contacts.component.scss',
  imports     : [
    ReactiveFormsModule ,
    CommonModule        ,
    SidebarModule       ,
    SidebarModule       ,
    CcSidebar           ,
    CcFormError         ,
    DropdownModule      ,
    TranslatePipe       ,
    OnlyNumber          ,

    TemplateDelete      ,
    TemplateImport
  ]
})
export class SidebarManageContacts extends CcBaseSidebar {

  private ts                        = inject(TranslateService);
  public $countryCollectionService  = inject(CountryCollectionService);

  public sidebarTitle  = this.ts.translate('@@MANAGE_CONTACTS.PAGE.NAME_CREATE');

  public $form = new CcFormGroup({
    firstName   : new FormControl(null, [Validators.required]),
    lastName    : new FormControl(null, [Validators.required]),
    fullName    : new FormControl(''),
    role        : new FormControl(null, [Validators.required]),
    phoneCc     : new FormControl(null, [Validators.required]),
    phone       : new FormControl(null, [Validators.required]),
    email       : new FormControl(null, [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    ]),
  });

  public ngOnChanges() {

    if(this.isInCreateMode()) {

      this.sidebarTitle = this.ts.translate('@@MANAGE_CONTACTS.PAGE.NAME_CREATE');
      this.$form.reset();
    }

    if(this.isInEditMode()) {

      this.sidebarTitle = this.ts.translate('@@MANAGE_CONTACTS.PAGE.NAME_EDIT');

      this.sidebarTitle = 'Edit contact';
      this.$form.controls['firstName'   ].setValue(this.inputData.firstName);
      this.$form.controls['lastName'    ].setValue(this.inputData.lastName);
      this.$form.controls['role'        ].setValue(this.inputData.role);
      this.$form.controls['phoneCc'     ].setValue(this.inputData.phoneCc);
      this.$form.controls['phone'       ].setValue(this.inputData.phone);
      this.$form.controls['email'       ].setValue(this.inputData.email);
    }

    if(this.isInDeleteMode()) {
      this.sidebarTitle = this.ts.translate('@@MANAGE_CONTACTS.PAGE.NAME_REMOVE');
    }
  }

  /**
   * @author Mihail Petrov
   */
  public async processForm() {

    this.$form.markFormGroupDirty().isFormValid(() => {

      this.returnToParent({
        ...this.inputData,
        ...this.$form.value
      });
    })
  }

  /**
   * @author Mihail Petrov
   */
  public removeEntity() {
    this.returnToParent(this.inputData);
  }

  /**
   * @author Mihail Petrov
   * @param event
   */
  public processFile(event: any) {

    const file: File  = event.files[0];
    const formData    = new FormData();
    formData.append('file', file, file.name);

    this.returnToParent(formData);
  }
}
