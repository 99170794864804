@if(inputIsVisible)
{
  <div class="card-box">

    <div>
      <i class="pi pi-tags fs32 m5"></i>
    </div>

    <div class="ml16 mt8">
      {{'@@SITE.TEMPLATE.DEMAND.DEMAND_SIDE_COST' | translate}}

      <div style="font-weight: bold;" class="mr16">Demand cost </div>

      <div class="just-flex mt8" style="align-items: center;">
        <div style="width: 126px;">Avg. demand cost per kW</div>
        <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
        class="pi pi-angle-double-right"></i>
        <div class="pill pill--green" style="font-size: 18px;">{{inputData?.yearlyAverageRate | currency:'USD'}}</div>
      </div>

      @if(inputData?.yearlyDemandCost != undefined)
      {
        <div class="just-flex mt8" style="align-items: center;">
          <div style="width: 126px;">Yearly demand cost</div>
          <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
          class="pi pi-angle-double-right"></i>
          <div class="pill pill--green" style="font-size: 18px;">{{inputData?.yearlyDemandCost | currency:'USD':'symbol':'1.0-0'}}</div>
        </div>
      }

      @if(inputData?.estimatedDemandCost != undefined)
      {
        <div class="just-flex mt8" style="align-items: center;">
          <div style="width: 126px;">Demand cost per kWh</div>
          <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
          class="pi pi-angle-double-right"></i>
          <div class="pill pill--green" style="font-size: 18px;">{{inputData?.estimatedDemandCost | currency:'USD'}}</div>
        </div>
      }

    </div>
  </div>
}
@else
{
  <div class="cc-placeholder">
    <i class="pi pi-tags fs32 m5"></i>
    <div class="ml16 fs24"> {{'@@SITE.TEMPLATE.DEMAND_SITE_COST.NAME' | translate}} </div>
  </div>
}
