import {  Component                  ,
          inject                     ,
          Input                      ,
          OnInit                     } from "@angular/core";
import {  FormArray                  ,
          FormGroup                  ,
          ReactiveFormsModule        } from "@angular/forms";
import {  DropdownModule             } from "primeng/dropdown";
import {  CalendarModule             } from "primeng/calendar";

import {  StateCollectionService     } from "app/@shared/collections/state.collection.service";
import {  CcFormGroup                } from "app/@shared/forms/form-group/cc-form-group.extention";
import {  FormUtil                   } from "nv@services/form-util.service";
import {  TranslatePipe              } from "nv@services/translate.service";
import {  SiteService                } from "../../@pages/site.service";
import { TemplateCapitalExpense      } from "nv@features/sites/@templates/template-capital-expense/template-capital-expense.component";
import { TemplateCostOperationMini   } from "nv@features/sites/@templates/template-cost-operation-mini/template-cost-operation-mini.component";
import { TemplateCostOperation       } from "nv@features/sites/@templates/template-cost-operation/template-cost-operation.component";
import { TemplateDemand              } from "nv@features/sites/@templates/template-demand/template-demand.component";
import { TemplateElectricityPrice    } from "nv@features/sites/@templates/template-electricity-price/template-electricity-price.component";
import { TemplatePortNumber          } from "nv@features/sites/@templates/template-port-number/template-port-number.component";
import { TemplatePricingDetails      } from "nv@features/sites/@templates/template-pricing-details/template-pricing-details.component";
import { TemplateRoi                 } from "nv@features/sites/@templates/template-roi/template-roi.component";
import { TemplateSiteLocation        } from "nv@features/sites/@templates/template-site-location/template-site-location.component";
import { TemplateUtilityService      } from "nv@features/sites/@templates/template-utility-service/template-utility-service.component";
import { TemplateTravelTraffic } from "nv@features/sites/@templates/template-travel-traffic/template-travel-traffic.component";
import { TemplateScore } from "nv@features/sites/@templates/template-score/template-score.component";
import { CcMap } from "../../../../@shared/components/cc-map/cc-map.component";
import { TemplateCompetition } from "../../@templates/template-competition/template-competition.component";
import { TemplateTopSummary } from "../../@templates/template-top-summary/template-top-summary.component";
import { TemplateBottomSummary } from "../../@templates/template-bottom-summary/template-bottom-summary.component";

@Component({
  selector    : 'wizzard-site-extended',
  templateUrl : './wizzard-site-extended.component.html',
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    TranslatePipe,
    TemplateSiteLocation,
    TemplatePortNumber,
    TemplateUtilityService,
    TemplateElectricityPrice,
    TemplatePricingDetails,
    TemplateRoi,
    TemplateDemand,
    TemplateCostOperation,
    TemplateCostOperationMini,
    TemplateCapitalExpense,
    TemplateTravelTraffic,
    TemplateScore,
    CcMap,
    TemplateCompetition,
    TemplateTopSummary,
    TemplateBottomSummary
]
})

export class WizzardSiteExtended implements OnInit {

    @Input() inputForm!: FormGroup;
    @Input() inputParentForm!: FormArray;
    @Input() isVisible: boolean = false;

    public fu: FormUtil             = inject(FormUtil);
    public $stateCollectionService = inject(StateCollectionService)
    private SiteService = inject(SiteService);

    @Input() inputData: any | null = null;
    @Input() inputUiState: any | null = null;

    public $form = new CcFormGroup({
    });

    public ngOnInit(): void {
        this.inputParentForm.push(this.$form);
    }
}
