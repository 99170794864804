import {  Component                  ,
          inject                     ,
          Input                      ,
          OnInit                     } from "@angular/core";
import {  FormArray                  ,
          FormControl                ,
          FormGroup                  ,
          ReactiveFormsModule        ,
          Validators                 } from "@angular/forms";
import {  DropdownModule             } from "primeng/dropdown";
import {  CalendarModule             } from "primeng/calendar";

import {  StateCollectionService     } from "app/@shared/collections/state.collection.service";
import {  CcFormGroup                } from "app/@shared/forms/form-group/cc-form-group.extention";
import {  CcFormError                } from "nv@components/cc-form-error/cc-form-error.component";
import {  CcMap                      } from "nv@components/cc-map/cc-map.component";
import {  TemplateFormHeader         } from "nv@features/sites/@templates/template-form-header/template-form-header.component";
import {  FormUtil                   } from "nv@services/form-util.service";
import {  TranslatePipe              } from "nv@services/translate.service";
import {  SiteService                } from "../../@pages/site.service";

@Component({
  selector    : 'wizzard-site-details',
  templateUrl : './wizzard-site-details.component.html',
  imports     : [
    TemplateFormHeader  ,
    ReactiveFormsModule ,
    CcFormError         ,
    CcMap               ,
    DropdownModule      ,
    CalendarModule      ,
    TranslatePipe
]
})
export class WizzardSiteDetails implements OnInit {

  @Input() inputForm!: FormGroup;
  @Input() inputParentForm!: FormArray;
  @Input() isVisible: boolean     = false;

  public fu: FormUtil             = inject(FormUtil);
  public $stateCollectionService  = inject(StateCollectionService)
  private SiteService             = inject(SiteService);

  public minDate                  = new Date();
  public mapParameters: any       = null;

  public $form = new CcFormGroup({
    name                        : new FormControl<string|null>(null, [Validators.required]),
    anticipatedStartDate        : new FormControl<string|null>(null, [Validators.required]),
    anticipatedStartDateFormat  : new FormControl<string|null>(null, [Validators.required]),
    state                       : new FormControl<string|null>(null, [Validators.required]),
    city                        : new FormControl<string|null>(null, [Validators.required]),
    zip                         : new FormControl<string|null>(null, [
      Validators.required,
      Validators.pattern("^[0-9]{5}$")
    ]),
    address                     : new FormControl<string|null>(null, { updateOn: 'blur', validators: [Validators.required]}),

    // hidden fields
    latLong                 : new FormControl<object|null>(null, [Validators.required])
  });

  public ngOnInit(): void {

    this.inputParentForm.push(this.$form);

    this.SiteService.serviceProcessor$.subscribe((result: any) => {

      if(result.formKey == 'process@GEOCODE') {

        this.$form.get('latLong')?.setValue(result.value);
        this.mapParameters = result.value;
        this.SiteService.sendData("init@UTILITY")
      }

      if(result.formKey == 'process@DATE') {
        this.$form.get('anticipatedStartDate')?.setValue(new Date(result.value))
      }
    });
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processStartDate($event: any) {

    const day   = $event.getDate();
    const month = $event.getMonth() + 1;
    const year  = $event.getFullYear();

    this.$form.get('anticipatedStartDateFormat')?.setValue(`${year}-${month}-${day}`);
  }

  /**
   * @author Mihail Petrov
   */
  public async processGeolocation(fieldId: string) {

    if(!this.$form.get('address')?.value  ) return;
    if(!this.$form.get('zip'  )?.value    ) return;
    if(!this.$form.get('city' )?.value    ) return;
    if(!this.$form.get('state')?.value    ) return;

    this.mapParameters = null;
    this.$form.get('latLong')?.setValue(null);

    this.SiteService.sendData('init@GEOCODE');
  }
}
