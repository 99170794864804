<div class="page-title mb32 flex">
  <h1 style="cursor: pointer; display: flex; align-items: center;" (click)="goBack()">
    <i class="pi pi-angle-left mr16"></i>
    <div class="mr16">{{projectDetail.name}}</div>
    <cc-project-label [inputStatus]="projectDetail.status"></cc-project-label>
  </h1>

  @if(isProjectHasScoredSites())
  {
    <div style="align-content: center;">
      <button class="btn btn-plane" (click)="processOpenReport()">
        <i class="pi pi-file-pdf"></i> {{'@@PROJECT.ACTION.DOWNLOAD_REPORT' | translate }}
      </button>
    </div>
  }
</div>

@if(isProjectStarted())
{
  <div class="page-title mb32">
    <h1>{{'@@PROJECT.PAGE.TITLE'| translate}}</h1>
  </div>

  <div class="flex">
    <div>
      <div (click)="navigateAddNewSite()" class="btn btn-primary">
        <i class="pi pi-plus"></i> {{'@@PROJECT.ACTION.CREATE' | translate}}
      </div>

      <div (click)="processImport()" class="btn btn-plane ml32">
        <i class="pi pi-upload"></i> {{'@@PROJECT.ACTION.IMPORT' | translate}}
      </div>
    </div>

    <div>
      <div (click)="processOnChangeProjectStatus()" class="btn btn-plane ml32">
        <i class="pi pi-cog"></i> {{'@@PROJECT.ACTION.CLOSE' | translate}}
      </div>
    </div>
  </div>

  @if(isProjectHasScoredSites())
  {
    <div class="flex mt32" style="width: 100%;">
      <template-summary
        [style]     = "{'width': '100%'}"
        [inputData] = "projectDetail.summary">
      </template-summary>
    </div>
  }

    <div class="mt32">
      <template-project-map
        [inputContactCollection]  = "contactList"
        [inputMarkerCollection]   = "projectDetail.summary?.mapMarkers"
        [inputSiteCollection]     = "projectDetail.sites"

        (onAdd)                   = "processOnContactAdd($event)"
        (onAssigneContact)        = "processOnContactEdit($event)">
      </template-project-map>
    </div>


  <div class="mt32">
    <cc-table
      [inputTableMapping]   = "$$ui.siteMapping"
      [inputDataSource]     = "siteCollection"
      [inputHasPaging]      = "true"

      (onNavigate)          = "processOnSiteEdit($event)"
      (onEdit)              = "processOnSiteEdit($event)"
      (onDelete)            = "processOnSiteDelete($event)"
      (onNext)              = "processOnChangePage($event)"
      (onPrev)              = "processOnChangePage($event)"
      (onSearch)            = "processOnSearchSite($event)">
    </cc-table>
  </div>
}


@if(isProjectNotStarted())
{
  <template-no-data-found
    (onAdd)     = "navigateAddNewSite()"
    (onImport)  = "processImport()">
  </template-no-data-found>
}

<!-- Reports -->
<sidebar-manage-reports
  [(inputIsVisible)]    = "$$ui.state.isSidebarManageReportsVisible"
  [inputData]           = "$$ui.data.sidebarTransactionObject"
  (onReturnObject)      = "processDownloadReport($event)">
</sidebar-manage-reports>

<!-- Sites -->
<sidebar-manage-sites
  [(inputIsVisible)]    = "$$ui.state.isSidebarManageSitesVisible"
  [inputData]           = "$$ui.data.sidebarTransactionObject"
  [inputSidebarAction]  = "$$ui.data.sidebarTransactionAction"
  (onReturnObject)      = "processRemoveSite($event)">
</sidebar-manage-sites>


<!--  -->
<sidebar-manage-project-contacts
  [inputData]           = "{transactionObject: $$ui.data.sidebarTransactionObject, contactList: contactCollection}"
  [(inputIsVisible)]    = "$$ui.state.isSidebarManageContactsVisible"
  [inputSidebarAction]  = "$$ui.data.sidebarTransactionAction"
  (onReturnObject)      = "processContact($event)">
</sidebar-manage-project-contacts>

<!--  -->
<sidebar-manage-contacts
  [(inputIsVisible)]    = "$$ui.state.isSidebarCreateContactVisible"
  [inputData]           = "$$ui.data.sidebarTransactionObject"
  [inputSidebarAction]  = "$$ui.data.sidebarTransactionAction"
  (onReturnObject)      = "processCreateNewContact($event)">
</sidebar-manage-contacts>

<!--  -->
<manage-project-status
  [(inputIsVisible)]    = "$$ui.state.isSidebarManageProjectStatus"
  [inputData]           = "$$ui.data.sidebarTransactionObject"
  [inputSidebarAction]  = "$$ui.data.sidebarTransactionAction"
  (onReturnObject)      = "processChangeProjectStatus($event)">
</manage-project-status>
