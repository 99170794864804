<form
  [formGroup]="$form">

  <template-form-header [inputIcon]="'map-marker'"
    [inputTitle]="'@@SITE.WIZZARD.DETAILS.ADDRESS_TITLE'">
  </template-form-header>

  <div class="mt16 flex">

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.DETAILS.SITE_NAME' | translate}} * </label>
      <input
            [class.is-invalid]  = "fu.isValid($form, 'name')"
            class               = "input"
            type                = "text"
            formControlName     = "name"
            placeholder         = "{{'@@SITE.WIZZARD.DETAILS.SITE_NAME_PLACEHOLDER' | translate}}"/>
            <cc-form-error [inputForm]="$form" [inputField]="'name'"></cc-form-error>
    </div>

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.DETAILS.PROJECT_START_DATE' | translate}} *</label>

      <div>
      <p-calendar
        formControlName = "anticipatedStartDate"
        placeholder     = "{{'@@SITE.WIZZARD.DETAILS.PROJECT_START_DATE_PLACEHOLDER' | translate}}"
        (onSelect)      = "processStartDate($event)"/>
      </div>
    </div>
    <div class="col mr16"></div>
  </div>

  <div class="mt16 flex">

    <div class="col2">
      <label>{{'@@SITE.WIZZARD.DETAILS.ADDRESS' | translate}} *</label>
      <input
            [class.is-invalid]  = "fu.isValid($form, 'address')"
            (blur)              = "processGeolocation('address')"
            class               = "input"
            type                = "text"
            formControlName     = "address"
            placeholder         = "{{'@@SITE.WIZZARD.DETAILS.ADDRESS_PLACEHOLDER' | translate}}"/>
            <cc-form-error [inputForm]="$form" [inputField]="'address'"></cc-form-error>
    </div>

    <div class="col mr16"></div>
  </div>

  <div class="mt32 flex">

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.DETAILS.CITY' | translate}} *</label>

      <input
            [class.is-invalid]  = "fu.isValid($form, 'city')"
            (blur)              = "processGeolocation('city')"
            class               = "input"
            type                = "text"
            formControlName     = "city"
            placeholder         = "{{'@@SITE.WIZZARD.DETAILS.CITY_PLACEHOLDER' | translate}}"/>
            <cc-form-error [inputForm]="$form" [inputField]="'address'"></cc-form-error>
      </div>


      <div class="col mr16">
        <label>{{'@@SITE.WIZZARD.DETAILS.STATE' | translate}} *</label>
        <p-dropdown
          formControlName         = "state"
          (onChange)              = "processGeolocation('state')"
          [filter]                = "true"
          filterBy                = "name"
          [options]               = "$stateCollectionService.getAllStates()"
          optionLabel             = "name"
          optionValue             = "name"
          placeholder             = "{{'@@SITE.WIZZARD.DETAILS.STATE_PLACEHOLDER' | translate}}" />
      </div>

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.DETAILS.ZIP' | translate}} *</label>
      <input
            [class.is-invalid]  = "fu.isValid($form, 'zip')"
            (blur)              = "processGeolocation('zip')"
            class               = "input"
            type                = "text"
            formControlName     = "zip"
            placeholder         = "{{'@@SITE.WIZZARD.DETAILS.ZIP_PLACEHOLDER' | translate}}"/>
            <cc-form-error [inputForm]="$form" [inputField]="'zip'" [inputValidatorType]="'zip'"></cc-form-error>
    </div>

  </div>

  @if(mapParameters) {
    <div class="mt32">
      <div class="just-flex mb16 mt32" style="color: #226092;">
        <i class="pi pi-map fs24 mt5"></i>
        <div class="fsb24 ml8">{{'@@SITE.WIZZARD.DETAILS.SITE_LOCATION_INFO' | translate}}</div>
      </div>
      <cc-map
        [inputType] = "'landmark'"
        [inputLat]  = "mapParameters.latitude"
        [inputLong] = "mapParameters.longitude">
      </cc-map>
    </div>
  }

</form>
