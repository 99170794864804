@if(inputIsVisible)
{
  <div class="card-box">

    <div>
      <i class="pi pi-dollar fs32 m5"></i>
    </div>

    <div class="ml16 mt8">
      <!-- {{'@@SITE.TEMPLATE.COST_OPERATION.NAME' | translate}} -->

      <div class="mr16">Annual operating expense <i class="pi pi-angle-double-right fs12 mt8 ml8 mr8"></i> {{inputData?.opex | currency:'USD':'symbol':'1.0-0'}}</div>


    </div>
  </div>
}
@else
{
  <div class="cc-placeholder">
    <i class="pi pi-tags fs32 m5"></i>
    <div class="ml16 fs24"> {{'@@SITE.TEMPLATE.COST_OPERATION.NAME' | translate}} </div>
  </div>
}
