import {  Component       ,
          Input           } from "@angular/core";
import {  AbstractControl ,
          FormGroup       } from "@angular/forms";
import {  CcFormGroup     } from "app/@shared/forms/form-group/cc-form-group.extention";
import {  TranslatePipe   } from "nv@services/translate.service";

@Component({
  selector    : 'cc-form-error',
  templateUrl : './cc-form-error.component.html',
  styleUrl    : './cc-form-error.component.scss',
  imports     : [
    TranslatePipe
  ]
})
export class CcFormError {


  @Input({required: true }) public inputForm!: FormGroup | CcFormGroup | AbstractControl;
  @Input({required: true }) public inputField!: string;
  @Input() public inputValidatorType!: string;
}
