import { Component            ,
         EventEmitter         ,
         Input                ,
         Output               } from "@angular/core";
import { CommonModule         } from "@angular/common";
import { ReactiveFormsModule  } from "@angular/forms";
import { SidebarModule        } from "primeng/sidebar";
import { CcFormError          } from "nv@components/cc-form-error/cc-form-error.component";
import { TranslatePipe        } from "nv@services/translate.service";

@Component({
  selector    : 'template-create',
  templateUrl : './template-create.component.html',
  imports     : [
    ReactiveFormsModule ,
    SidebarModule       ,
    CcFormError         ,
    TranslatePipe       ,
    CommonModule
  ]
})
export class TemplateCreate {

  @Input() public $form: any;
  @Input() public inputData: any;
  @Input() public projectContactList: any;

  @Output() public onProcess              = new EventEmitter();
  @Output() public onAddContact           = new EventEmitter();
  @Output() public onMakeContactPrimary   = new EventEmitter();
  @Output() public onRemoveContact        = new EventEmitter();
  @Output() public onAddContactToProject  = new EventEmitter();
}
