import { Component      } from "@angular/core";
import { TranslatePipe  } from "nv@services/translate.service";

@Component({
  selector    : 'page-not-found',
  templateUrl : './page-not-found.component.html',
  imports     : [
    TranslatePipe
  ]
})
export class PageNotFound {

}
