<div style="position: relative; border: solid #dedede 1px; border-radius: 4px;">
  <div style="width: 600px; height: 100%; background: #fff; position: absolute; top: 0; left: 0; z-index: 1100; padding: 16px;">

    @if(inputContactCollection && inputContactCollection.length > 0)
    {
      <div class="flex" style="align-items: center;">
        <div style="font-weight: bold; font-size: 22px;">{{'@@PROJECT.TEMPLATE.PROJECT_MAP.CONTACTS' | translate}}</div>
        <div (click)="onAdd.emit()" class="btn btn-primary mt16 mb16">
          <i class="pi pi-plus mr16"></i>
          Add new contact
        </div>
      </div>

      <div style="height: 260px; overflow: auto;">
        @for(element of inputContactCollection; track element)
        {
          <div class="flex contact-element pt8 pb8" style="border-bottom: solid #dedede 1px;">
            <div>
              <div style="font-weight: bold;">{{element?.firstName}} {{element?.lastName}}</div>
              <div>{{element?.role}}</div>
            </div>
            <div class="flex" style="align-items: center;">
              @if(element.isPrimary)
              {
                <div style="background: #c1ef5c; padding: 8px; border-radius: 5px; margin-right: 16px;">Primary</div>
              }

              <a href="tel:{{element.phoneCc}}{{ element.phone}}">
                <i mail class="pi pi-phone m16"></i>
              </a>
              <a href="mailto:{{element.email}}">
                <i  class="pi pi-envelope m16"></i>
              </a>
            </div>
          </div>
        }
      </div>

      <div (click)="onAssigneContact.emit()" class="btn btn--wide btn-plane mt16 mb16 mr16">
        <i class="pi pi-plus mr16"></i>{{'@@MANAGE_PROJECTS.ACTION.MANAGE_CONTACTS' | translate }}
      </div>
    }
    @else {

      <div class="no-data-found" style="width: 100%; height: 50%; margin-top: 0; padding-top: 128px;">

        <i class="pi pi-flag fs46"></i>
        <div class="fsb32 mt8">{{'@@PROJECT.TEMPLATE.PROJECT_MAP.NO_DATA_FOUND' | translate }}  </div>
        <div class="fs16 mt16">{{'@@PROJECT.TEMPLATE.PROJECT_MAP.NO_DATA_FOUND_SUB' | translate }}</div>

        <div (click)="onAdd.emit()" class="btn btn-primary mt16 mb16 mr16">
          <i class="pi pi-plus mr16"></i>{{'@@MANAGE_PROJECTS.ACTION.CONTACT_CREATE' | translate }}
        </div>

        <div (click)="onAssigneContact.emit()" class="btn btn-primary mt16 mb16">
          <i class="pi pi-plus mr16"></i>{{'@@MANAGE_PROJECTS.ACTION.ATTACH_CONTACT' | translate }}
        </div>
      </div>
    }
  </div>

  <cc-map
    [markerLocations]         = "inputMarkerCollection"
    [inputReferencedObjects]  = "inputSiteCollection">
  </cc-map>
</div>
