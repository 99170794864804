import {  Component       ,
          EventEmitter    ,
          inject          ,
          Input           ,
          Output          } from "@angular/core";
import {  CcTable         } from "nv@components/cc-table/cc-table.component";
import {  CustomerState   } from "nv@features/customers/@pages/customers.state";
import {  TranslatePipe   } from "nv@services/translate.service";

@Component({
  selector    : 'template-contact-tab',
  templateUrl : './template-contact-tab.component.html',
  providers   : [CustomerState],
  imports     : [TranslatePipe, CcTable]
})
export class TemplateContactTab {

  @Input()  public inputDataSource: any;
  @Input()  public inputHasData = false;

  @Output() public onCreate     = new EventEmitter();
  @Output() public onImport     = new EventEmitter();
  @Output() public onEdit       = new EventEmitter();
  @Output() public onDelete     = new EventEmitter();

  @Output() public onNavigate   = new EventEmitter();
  @Output() public onPageChange = new EventEmitter();
  @Output() public onSearch     = new EventEmitter();

  public $$ui                   = inject(CustomerState)
}
