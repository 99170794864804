import { Injectable } from "@angular/core";

@Injectable()
export class SiteState {

  public state = {
    isSiteLocationPreviewVisible      : false,
    isUtilityDetailsPreviewVisible    : false,
    isPricingDetailsPreviewVisible    : false,
    isRoiPreviewVisible               : false,
    roiPeriod                         : null,
    expectedAvgWeeklyVisits           : 0,
    npv                               : 0,
    expectedMonthlyEnergyConsumption  : 0,
    isReportAvailable                 : false,
    isSidebarManageReportsVisible     : false,


    isDemandDetailsPreviewVisible : false
  };


  public data = {
    sidebarTransactionObject          : null
  }
}
