import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class UtilityCollectionService {

  /**
   * @author Mihail Petrov
   * @returns
   */
  public getHardwareConfigurationSetup() {

    return this.getHardwareConfiguration().filter((item, index, self) =>
      index === self.findIndex(otherItem => otherItem.name === item.name)
    );
  }

  /**
   * @author Mihail Petrov
   * @param id
   */
  public get(id: string, options: { hasNACScable: boolean, isBabaApplicable: string, isNaviCamplient: boolean }) {

    for(const element of this.getHardwareConfiguration()) {

      if(element.name               != id                       ) continue;
      if(element.hasNACScable       != options.hasNACScable     ) continue;
      if(element.isNaviCamplient    != options.isNaviCamplient  ) continue;

      if( (element.isBabaApplicable != 'NA') &&
          (element.isBabaApplicable != options.isBabaApplicable )) {
        continue;
      }

      return element;
    }

    return null;
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public getHardwareConfiguration() {

    return [
      {
        name              : 'All in One 200 kW Charger'  ,
        numberOfPorts     : 2,
        hardwareCost      : 81250,
        installationCost  : 81250,
        capacity          : 200,
        hasNACScable      : false,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false,
        monthlyCost       : 265.95
      },
      {
        name              : 'All in One 200 kW Charger'  ,
        numberOfPorts     : 2,
        hardwareCost      : 81250,
        installationCost  : 81250,
        capacity          : 200,
        hasNACScable      : true,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false,
        monthlyCost       : 265.95
      },
      {
        name              : 'All in One 200 kW Charger'  ,
        numberOfPorts     : 1,
        hardwareCost      : 90625,
        installationCost  : 90625,
        capacity          : 200,
        hasNACScable      : true,
        isBabaApplicable  : 'Y',
        isNaviCamplient   : false,
        monthlyCost       : 132.98
      },

      // {
      //   name              : 'Distributed Solution 600 kW - 1 Charger'  ,
      //   numberOfPorts     : 2,
      //   hardwareCost      : 225000,
      //   installationCost  : 225000,
      //   capacity          : 600,
      //   hasNACScable      : false,
      //   isBabaApplicable  : 'NA',
      //   isNaviCamplient   : false
      // },
      // {
      //   name              : 'Distributed Solution 600 kW - 1 Charger'  ,
      //   numberOfPorts     : 2,
      //   hardwareCost      : 225000,
      //   installationCost  : 225000,
      //   capacity          : 600,
      //   hasNACScable      : true,
      //   isBabaApplicable  : 'NA',
      //   isNaviCamplient   : false
      // },


      {
        name              : 'Distributed Solution 600 kW - 2 Chargers'  ,
        numberOfPorts     : 4,
        hardwareCost      : 287500,
        installationCost  : 287500,
        capacity          : 600,
        hasNACScable      : false,
        isBabaApplicable  : 'N',
        isNaviCamplient   : false,
        monthlyCost       : 1063.80
      },
      {
        name              : 'Distributed Solution 600 kW - 2 Chargers'  ,
        numberOfPorts     : 4,
        hardwareCost      : 291250,
        installationCost  : 291250,
        capacity          : 600,
        hasNACScable      : true,
        isBabaApplicable  : 'N',
        isNaviCamplient   : false,
        monthlyCost       : 1063.80
      },
      {
        name              : 'Distributed Solution 600 kW - 2 Chargers'  ,
        numberOfPorts     : 4,
        hardwareCost      : 406250,
        installationCost  : 406250,
        capacity          : 600,
        hasNACScable      : false,
        isBabaApplicable  : 'Y',
        isNaviCamplient   : false,
        monthlyCost       : 1063.80
      },
      {
        name              : 'Distributed Solution 600 kW - 2 Chargers'  ,
        numberOfPorts     : 4,
        hardwareCost      : 410000,
        installationCost  : 410000,
        capacity          : 600,
        hasNACScable      : true,
        isBabaApplicable  : 'Y',
        isNaviCamplient   : false,
        monthlyCost       : 1063.80
      },

      {
        name              : 'Distributed Solution 600 kW - 4 Chargers'  ,
        numberOfPorts     : 4,
        hardwareCost      : 430000,
        installationCost  : 430000,
        capacity          : 600,
        hasNACScable      : false,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false,
        monthlyCost       : 2127.60
      },
      {
        name              : 'Distributed Solution 600 kW - 4 Chargers'  ,
        numberOfPorts     : 4,
        hardwareCost      : 437500,
        installationCost  : 437500,
        capacity          : 600,
        hasNACScable      : true,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false,
        monthlyCost       : 2127.60
      },

      // {
      //   name              : 'Wallbox 30 kW Charger'  ,
      //   numberOfPorts     : 1,
      //   hardwareCost      : 12000,
      //   installationCost  : 12000,
      //   capacity          : 30,
      //   hasNACScable      : false,
      //   isBabaApplicable  : 'NA',
      //   isNaviCamplient   : false
      // },
      // {
      //   name              : 'Wallbox 30 kW Charger'  ,
      //   numberOfPorts     : 1,
      //   hardwareCost      : 12000,
      //   installationCost  : 12000,
      //   capacity          : 30,
      //   hasNACScable      : true,
      //   isBabaApplicable  : 'NA',
      //   isNaviCamplient   : false
      // }
    ];
  }
}
