import { Injectable         } from "@angular/core";
import { Observable         } from "rxjs";

import { ContactModel       } from "nv@models/contact.model";
import { CustomerModel      } from "nv@models/customers.model";
import { ApiResponseModel   ,
         HttpApiService     } from "nv@services/http.service";


export type Meta = {
  page: number,
  limit?: number,
  filter: any
}


@Injectable({
  providedIn: 'root'
})
export class CustomerApi extends HttpApiService {

  /**
   * @author Mihail Petrov
   * Register new patient
   * @param $object
   * @returns
   */
  public getAllCustomers($meta?: any): Observable<ApiResponseModel> {

    return this.forEndpoint     (`application`, `customers`)
               .withQuery       ("page"   , $meta?.page   || 0)
               .withQuery       ("limit"  , $meta?.limit  || 5)
               .withFilterQuery ("name"   , $meta?.name)
               .fetch           ()
  }

  /**
   * @author Mihail Petrov
   * Register new patient
   * @param $object
   * @returns
   */
  public getCustomer($id: string): Observable<ApiResponseModel>  {

    return this.forEndpoint(`application`, `customers`, $id)
               .fetch();
  }

  /**
   * @author Mihail petrov
   * @param $name
   */
  public findCustomerByName($name: string): Observable<ApiResponseModel>   {

    return this.forEndpoint(`application`, `customers`)
               .withFilterQuery("name", $name)
               .fetch();
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public createNewCustomer($object: CustomerModel): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `customers`)
               .withBody($object)
               .create();
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public importCustomer($object: any): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `customers`, `import`)
               .withBody($object)
               .create();
  }


  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public updateCustomer($object: CustomerModel): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `customers`)
               .withBody($object)
               .update();
  }

  /**
   * @author Mihail Petrov
   * @param $id
   */
  public removeCustomer($id: string): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `customers`, $id)
               .remove();
  }

  /**
 * @author Mihail Petrov
 * @param $object
 * @returns
 */
  public createNewProject($object: any): Observable<ApiResponseModel>  {

    return this.forEndpoint(`application`, `projects`)
               .withBody($object)
               .create();
  }

  /**
 * @author Mihail Petrov
 * @param $object
 * @returns
 */
  public createNewContact($object: any): Observable<ApiResponseModel>  {

    return this.forEndpoint(`application`, `contacts`)
               .withBody($object)
               .create();
  }


  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public importContact($object: any): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `contacts`, `import`)
               .withBody($object)
               .create();
  }


    /**
 * @author Mihail Petrov
 * @param $object
 * @returns
 */
    public updateContact($object: ContactModel): Observable<ApiResponseModel>  {

      return this.forEndpoint(`application`, `contacts`)
                 .withBody($object)
                 .update();
    }

/**
 * @author Mihail Petrov
 * @param $object
 * @returns
 */
  public removeContact($id: string): Observable<ApiResponseModel>  {

    return this.forEndpoint(`application`, `contacts`, $id)
               .remove();
  }

  /**
   * @author Mihail Petrov
   * @param $id
   * @returns
   */
  public getAllProjectsForCustomer($id: string, $meta?: any): Observable<ApiResponseModel>  {

    return this.forEndpoint     (`application`, `customers`, $id, `projects`)
               .withQuery       ("page"   , $meta?.page || 0  )
               .withQuery       ("limit"  , $meta?.limit || 5 )
               .withFilterQuery ("name"   , $meta?.name)
               .fetch           ();
  }

  /**
   * @author Mihail Petrov
   * @param $id
   * @returns
   */
  public getAllContactsForCustomer($id: string, $meta?: any): Observable<ApiResponseModel>  {

    return this.forEndpoint     (`application`, `customers`, $id, `contacts`)
               .withQuery       ("page"       , $meta?.page || 0  )
               .withQuery       ("limit"      , $meta?.limit || 5 )
               .withFilterQuery ("full_name"  , $meta?.fullName)
               .fetch           ();
  }
}
