import {  CommonModule    } from '@angular/common';
import {  Component       ,
          Input           } from '@angular/core';
import {  TranslatePipe   } from 'nv@services/translate.service';

@Component({
  selector    : 'template-site-location',
  templateUrl : './template-site-location.component.html',
  imports     : [ CommonModule, TranslatePipe ]
})
export class TemplateSiteLocation {

  @Input() public inputData: any;
  @Input() public inputIsVisible = false
}
