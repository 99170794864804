@if(inputIsVisible)
{
  <div class="card-box">

    <div>
      <i class="pi pi-dollar fs32 m5"></i>
    </div>

    <div class="ml16 mt8">
      <!-- {{'@@SITE.TEMPLATE.COST_OPERATION.NAME' | translate}} -->

      <div style="font-weight: bold;" class="mr16">Operating Expense (1 year)</div>

      @if(inputData?.costFixed != undefined)
      {
        <div class="just-flex mt8" style="align-items: center;">
          <div style="width: 126px;">Yearly fixed electricity cost</div>
          <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
          <div class="pill pill--red" style="font-size: 18px;">{{inputData?.costFixed | currency:'USD':'symbol':'1.0-0'}}</div>
        </div>
      }

      @if(inputData?.costEnergy != undefined)
      {
        <div class="just-flex mt8" style="align-items: center;">
          <div style="width: 126px;">Yearly energy cost</div>
          <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
          <div class="pill pill--red" style="font-size: 18px;">{{inputData?.costEnergy | currency:'USD':'symbol':'1.0-0'}}</div>
        </div>
      }

      @if(inputData?.costDemand != undefined)
      {
        <div class="just-flex mt8" style="align-items: center;">
          <div style="width: 126px;">Yearly demand cost</div>
          <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
          <div class="pill pill--red" style="font-size: 18px;">{{inputData?.costDemand | currency:'USD':'symbol':'1.0-0'}}</div>
        </div>
      }

      <div class="just-flex mt8" style="align-items: center;">
        <div style="width: 126px;">Yearly other expenses</div>
        <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
        <div class="pill pill--red" style="font-size: 18px;">{{inputData?.operatingCost * 12 | currency:'USD':'symbol':'1.0-0'}}</div>
      </div>


      <div class="just-flex mt8" style="align-items: center; border-top: solid #dedede 1px; padding-top: 8px;">
        <div style="width: 126px;">Total cost</div>
        <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
        <div class="pill" style="font-size: 18px;">{{inputData?.opex | currency:'USD':'symbol':'1.0-0'}}</div>
      </div>

    </div>
  </div>
}
@else
{
  <div class="cc-placeholder">
    <i class="pi pi-tags fs32 m5"></i>
    <div class="ml16 fs24"> {{'@@SITE.TEMPLATE.COST_OPERATION.NAME' | translate}} </div>
  </div>
}
