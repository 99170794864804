import { Component                    ,
         inject                       ,
         OnInit                       } from "@angular/core";
import { ActivatedRoute               } from "@angular/router";

import { lastValueFrom                } from "rxjs";
import { ButtonModule                 } from 'primeng/button';
import { TableModule                  } from 'primeng/table'

import { ProjectApi                   } from "nv@api/project.api.service";
import { SiteApi                      } from "nv@api/site.api.service";
import { ReportApi                    } from "nv@api/report.api.service";
import { GlobalRedirectService        } from "app/@routes/redirect.service";
import { CcTable                      } from "nv@components/cc-table/cc-table.component";
import { ProjectModel                 } from "nv@models/project.model";
import { ReportModel                  } from "nv@models/report.model";
import { TranslatePipe                } from "nv@services/translate.service";

import { SidebarManageProjectContacts } from "nv@features/@modals/manage-project-contacts/manage-project-contacts.component";
import { SidebarManageProjectStatus   } from "nv@features/@modals/manage-project-status/manage-project-status.component";
import { CcProjectLabel               } from "nv@components/cc-project-label/cc-project-label.component";
import { CustomerApi                  } from "nv@api/customer.api.service";

import { SidebarManageSites           } from "../../@modals/manage-sites/manage-sites.component";
import { SidebarManageReports         } from "../../@modals/manage-reports/manage-reports.component";
import { TemplateSummary              } from "../@templates/template-summary/template-summary.component";
import { TemplateNoDataFound          } from "../@templates/template-no-data-found/template-no-data-found.component";
import { TemplateProjectMap           } from "../@templates/template-project-map/template-project-map.component";
import { ProjectState                 } from "./project.state";
import { SidebarManageContacts        } from "nv@features/@modals/manage-contacts/manage-contacts.component";

@Component({
  selector    : 'page-project',
  templateUrl : './project.component.html',
  providers   : [ProjectState],
  imports     : [
    ButtonModule                  ,
    TableModule                   ,
    CcTable                       ,
    CcProjectLabel                ,

    SidebarManageSites            ,
    SidebarManageReports          ,
    SidebarManageProjectContacts  ,
    SidebarManageContacts         ,
    SidebarManageProjectStatus    ,

    TranslatePipe                 ,
    TemplateSummary               ,
    TemplateNoDataFound           ,
    TemplateProjectMap            ,
  ]
})
export class ProjectPage implements OnInit {

  public $$ui                 = inject(ProjectState)
  public globalRedirect       = inject(GlobalRedirectService);
  private $customerApiService = inject(CustomerApi);
  private $projectApiService  = inject(ProjectApi);

  private route               = inject(ActivatedRoute);
  private $projectApi         = inject(ProjectApi)
  private $reportApi          = inject(ReportApi)
  private $siteApi            = inject(SiteApi)

  public projectDetail!: ProjectModel;
  public contactCollection!: any[];

  public mapParameters: any;

  public siteCollection: any  = [];
  public contactList: any     = []

  public markerCollection: any = [];
  public reportCollection: any;

  public async ngOnInit() {

    this.projectDetail  = this.route.snapshot.data['data'];
    this.contactList    = this.projectDetail.contacts;

    const scores = this.projectDetail.summary.projectScores;
    const sites = this.projectDetail.sites;

    this.siteCollection = this.mergeSitesWithScores(sites, scores);
  }

  public async fetchProject() {

    const id            = this.projectDetail.id;
    const response      = await lastValueFrom(this.$projectApi.getProject(id as string));
    this.projectDetail  = response.data;

    const scores = this.projectDetail.summary.projectScores;
    const sites = this.projectDetail.sites;

    this.siteCollection = sites.map((site: any) => {
        const matchedScore = scores.find((score: any) => score.id === site.id);
        return {
            ...site, // Copy all existing site data
            market_potential_score: matchedScore?.market_potential_score ?? 0,
            charging_infrastructure_score: matchedScore?.charging_infrastructure_score ?? 0,
            financial_utilization_performance_score: matchedScore?.financial_utilization_performance_score ?? 0,
            nevi_score: matchedScore?.nevi_score ?? 0
        };
    });
    
  }


  /**
   * @author Mihail Petrov
   * @returns boolean
   */
  public isProjectStarted(): boolean {

    return  this.projectDetail?.sites &&
            this.projectDetail?.sites.length > 0
  }

  /**
   * @author Mihail Petrov
   * @returns boolean
   */
  public isProjectHasScoredSites(): boolean {

    return  this.projectDetail?.sites             &&
            this.projectDetail?.sites.length > 0  &&
            this.projectDetail.summary?.numberOfScoredSites > 0
  }

  /**
   *
   * @returns
   */
  public isProjectisNotScoredYet(): boolean {

    return  this.projectDetail?.sites             &&
            this.projectDetail?.sites.length > 0  &&
            this.projectDetail.summary?.numberOfScoredSites == 0
  }



  /**
   * @author Mihail Petrov
   * @returns boolean
   */
  public isProjectNotStarted(): boolean {
    return !this.isProjectStarted();
  }


  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processOnSiteEdit($event: any) {
    this.globalRedirect.inContextOfApplication().viewSite($event.id);
  }

  /**
   * @author Mihail Petrov
   */
  public async processOpenReport() {

    const projectId             = this.projectDetail.id as string;
    const reportCollection: any = (await lastValueFrom(this.$reportApi.getSummaryReport(projectId))).data;

    this.$$ui.state.isSidebarManageReportsVisible = true;
    this.$$ui.data.sidebarTransactionObject       = reportCollection;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processOnSiteDelete($event: any) {

    this.$$ui.data.sidebarTransactionAction     = 'delete'
    this.$$ui.state.isSidebarManageSitesVisible = true;
    this.$$ui.data.sidebarTransactionObject     = $event;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public async processOnContactAdd($event: any) {

    this.$$ui.data.sidebarTransactionAction         = 'create';
    this.$$ui.state.isSidebarCreateContactVisible   = true;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public async processOnContactEdit($event: any) {

    this.contactCollection  = (await lastValueFrom(this.$customerApiService.getAllContactsForCustomer(this.projectDetail.customerId!))).data;
    this.$$ui.data.sidebarTransactionAction        = 'edit';
    this.$$ui.data.sidebarTransactionObject        = this.projectDetail as any;
    this.$$ui.state.isSidebarManageContactsVisible = true;
  }

  /**
   * @author Mihail Petrov
   */
  public processImport() {

    this.$$ui.data.sidebarTransactionAction        = 'import';
    this.$$ui.data.sidebarTransactionObject        = this.projectDetail as any;
    this.$$ui.state.isSidebarManageSitesVisible    = true;
  }

  /**
   * @author Mihail Petrov
   */
  public navigateAddNewSite() {

    const projectId   = this.projectDetail.id as string;
    this.globalRedirect.inContextOfCustomer().createNewSite(projectId);
  }

  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async processRemoveSite($entity: any) {

    if(this.$$ui.data.sidebarTransactionAction == 'delete') {

      await lastValueFrom(this.$siteApi.removeSite($entity.id!))
      await this.fetchProject();
    }

    if(this.$$ui.data.sidebarTransactionAction == 'import') {

      $entity.append('projectId', this.projectDetail.id);
      await lastValueFrom(this.$siteApi.importSite($entity));
      await this.fetchProject();
    }
  }

  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async processCreateNewContact($entity: any) {

    $entity.customerId = this.projectDetail.customerId;
    await lastValueFrom(this.$customerApiService.createNewContact($entity))
  }

  /**
   *
   */
  public async processContact($entity: ProjectModel) {

    try {
      await lastValueFrom(this.$projectApiService.updateProject($entity))
      this.contactList = this.projectDetail.contacts;

    } catch(error) {
      console.log(error)
    }
  }

  /**
   *
   */
  public processOnChangeProjectStatus() {

    this.$$ui.state.isSidebarManageProjectStatus  = true;
    this.$$ui.data.sidebarTransactionObject       = this.projectDetail as any;
  }

  /**
   * @author Mihail Petrov
   * @param status
   */
  public async processChangeProjectStatus($entity: any) {
    await lastValueFrom(this.$projectApiService.updateProject($entity));
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processDownloadReport($event: ReportModel) {
    window.open($event.url, '_blank');
  }

  /**
   * @author Mihail Petrov
   * @param $pageId
   */
  public async processOnChangePage($pageId: any) {

    const response = await lastValueFrom(this.$projectApi.getAllSitesForProject(this.projectDetail.id!, $pageId));
    
    const sites = response.data;
    const scores = this.projectDetail.summary.projectScores;
    this.siteCollection = this.mergeSitesWithScores(sites, scores);
  }


  /**
   * @author Mihail Petrov
   * @param $searchValue
   */
  public async processOnSearchSite($searchValue: string) {

    const response = await lastValueFrom(this.$projectApi.getAllSitesForProject(this.projectDetail.id!, { name: $searchValue }));
    
    const sites = response.data;
    const scores = this.projectDetail.summary.projectScores;
    this.siteCollection = this.mergeSitesWithScores(sites, scores);
  }

  /**
   *
   */
  public goBack() {
    this.globalRedirect.inContextOfApplication().viewCustomer(this.projectDetail.customerId!);
  }

  private mergeSitesWithScores(
    sites: any[], 
    scores: any[]
  ): any[] {

    return sites.map((site: any) => {
      const matchedScore = scores.find((score: any) => score.id === site.id);
      return {
        ...site,
        market_potential_score: matchedScore?.market_potential_score ?? 0,
        charging_infrastructure_score: matchedScore?.charging_infrastructure_score ?? 0,
        financial_utilization_performance_score: matchedScore?.financial_utilization_performance_score ?? 0,
        nevi_score: matchedScore?.nevi_score ?? 0
      };
    });
  }
}
