<div>
  <div class="table-wrapper">
    <div style="padding-bottom: 16px; position: relative;">
      <i style="position: absolute; left: 12px; top: 12px;" class="pi pi-search"></i>
      <input
        (input)     = "processSearch()"
        [(ngModel)] = "searchInput"
        placeholder = "Search by name"
        style       = "border: solid #dedede 1px; padding: 8px; width: 100%; padding-left: 42px;"
        type        = "text">
    </div>

    @if(virtualDataSource && virtualDataSource.length > 0)
    {
      <table class="normal-table">

        <tr class="normal-header">

          @for(headerElement of this.inputTableMapping; track headerElement)
          {
            <td>{{headerElement.column}}</td>
          }

          @if(uiState.isEditVisible) {
            <td>Edit</td>
          }

          @if(uiState.isDeleteVisible) {
            <td>Delete</td>
          }
        </tr>

        @for(element of virtualDataSource | slice:0:inputLimit; track element)
        {
          <tr class="normal-row">

            @for(contentElement of this.inputTableMapping; track contentElement) {
              <td
                [ngClass]="contentElement?.isNavigatable ? 'primary' : ''"
                (click)="processOnNavigate(element, contentElement)">

                @if(contentElement?.icon)
                {
                  <i class="{{contentElement?.icon}} mr16"></i>
                }

                @if(contentElement?.directive == 'project-label' && contentElement?.value != undefined)
                {
                  <cc-project-label
                    [inputStatus]="renderColumn(element, contentElement)">
                  </cc-project-label>
                }

                @if(contentElement?.classId)
                {
                  <div  [ngClass]="contentElement.classId" 
                        (mouseenter)="showTooltip($event, contentElement?.tooltipFn ? contentElement.tooltipFn!(element) : null)"
                        (mouseleave)="hideTooltip()">
                    {{ renderColumn(element, contentElement)}}
                  </div>
                }
                @else if (contentElement?.classIdFn)
                {
                  <div  [ngClass]="getClassIdByFn(element, contentElement)"
                        (mouseenter)="showTooltip($event, contentElement?.tooltipFn ? contentElement.tooltipFn!(element) : null)"
                        (mouseleave)="hideTooltip()">
                    {{ renderColumn(element, contentElement)}}
                  </div>
                }
                @else
                {
                  {{ renderColumn(element, contentElement)}}
                }


                <!-- @else
                {
                  {{ renderColumn(element, contentElement)}}
                } -->

                <!-- Tooltip for displaying scores -->
                <!-- <div *ngIf="tooltipData" class="tooltip-popup active" [ngStyle]="tooltipPosition">
                  <div *ngFor="let score of tooltipData; let i = index" class="tooltip-bar">
                    <div class="tooltip-text-container">
                      <div class="tooltip-title">{{ score.title }}</div>
                      <div class="tooltip-value">{{ score.value }} / 100</div>
                    </div>
                    <div class="progress-bar">
                      <div class="progress-fill" 
                          [style.width.%]="score.value"
                          [ngStyle]="{'background-color': getBarColor(i)}">
                      </div>
                    </div>
                  </div>
                </div> -->

                @if (tooltipData)
                {
                  <div class="tooltip-popup active" [ngStyle]="tooltipPosition">
                    @for (score of getDisplayItems(); track score; let i = $index)
                    {
                      @if(i != 3)
                     { 
                        <div class="tooltip-bar">
                          <div class="tooltip-text-container">
                            <div class="tooltip-title">{{ score.title  == "Infrastructure" ? "Competition" : score.title}}</div>
                          </div>
                          <div class="progress-bar">
                            <div class="progress-fill"
                              [style.width.%]="(score.value / getMaxValue(i)) * 100"
                              [ngStyle]="{'background-color': getBarColor(i)}">
                            </div>
                          </div>
                        </div>
                      }
                    }
                  </div>
                }


              </td>
            }

            @if(uiState.isEditVisible) {
              <td (click)="processOnEdit(element)" style="cursor: pointer;">
                <i style="color: #226092" class="pi pi-user-edit"></i>
              </td>
            }

            @if(uiState.isDeleteVisible) {
              <td (click)="processOnDelete(element)" style="cursor: pointer;">
                <i style="color: #ff0000" class="pi pi-trash"></i>
              </td>
            }

          </tr>
        }

      </table>
    }
    @else
    {
      <div class="fsb32 mt32 mb32 no-query-found">{{ '@@CC_TABLE.NO_DATA_FOUND'    | translate }} </div>
    }
  </div>

  @if(inputHasPaging)
  {
    <div class="just-flex ml8 mt16" style="align-items: center; cursor: pointer;">

      @if((this.inputPage > 0))
      {
        <button (click)="processOnPrev()" class="btn btn-primary mr16">
          <i class="pi pi-angle-left"></i>
          Prev
        </button>
      }

      @if((inputDataSource.length > inputLimit) && (inputDataSource.length > 0))
      {
        <button (click)="processOnNext()" class="btn btn-primary">
          Next
          <i class="pi pi-angle-right"></i>
        </button>
      }

    </div>
  }
</div>
