import {  Component      ,
          EventEmitter   ,
          Input          ,
          Output         } from "@angular/core";
import {  CommonModule   } from "@angular/common";
import {  TranslatePipe  } from "nv@services/translate.service";

@Component({
  selector    : 'template-no-data-found',
  templateUrl : './template-no-data-found.component.html',
  imports     : [
    CommonModule,
    TranslatePipe
  ]
})
export class TemplateNoDataFound {

  @Input() public inputData!: any;
  @Output() public onAdd    = new EventEmitter();
  @Output() public onImport = new EventEmitter();
}
