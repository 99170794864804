<div class="flex" style="width: 100%;">

  <!-- Location and utility Card -->
  <div  class="box" style="display: flex; flex-direction: column;">

    <div class="box" style="height: 100%; border: none; border-bottom: solid #dedede 1px;">
      <div class="icon">
        <i class="pi pi-map-marker"></i>
        <div class="title">{{inputData?.address }}</div>
      </div>

      <div class="value" style="font-size: 18px;">{{inputData?.zip}} {{inputData?.city}} {{inputData?.state}}</div>
    </div>


    <div class="box" style="height: 100%; border: none;">
      <div class="icon">
        <i class="pi pi-truck"></i>
        <div class="title">{{ inputData?.utility     }}</div>
      </div>

      <div class="value" style="font-size: 16px;">{{ inputData?.utilityPlan }}</div>
    </div>
  </div>

  <!-- Operating expence Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-dollar"></i>
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.OPERATING_EXPENSE' | translate}}</div>
    </div>

    <!--  -->
    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.FIXED_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData?.costFixed | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.DEMAND_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData?.costDemand | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.ENERGY_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData?.costEnergy | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.OTHER_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData?.operatingCost * 12 | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="value" style="font-size: 16px;">{{(inputData?.opex)| currency:'USD':'symbol':'1.0-0'}}</div>
  </div>

  <!-- Capital expence Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-dollar"></i>
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.TOTAL_EXPENCE' | translate}}</div>
    </div>

    @if(inputData?.incentives > 0)
    {
      <div class="flex mt8">
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.INCENTIVES' | translate}}</div>
        <strong style="color: #6bbe6f;">{{inputData?.incentives | currency:'USD':'symbol':'1.0'}}</strong>
      </div>
    }

    @if(inputData?.hasIncentives30c)
    {
      <div class="flex mt8">
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.INCENTIVE30C' | translate}}</div>
        <strong style="color: #6bbe6f;">{{100000 | currency:'USD':'symbol':'1.0'}}</strong>
      </div>
    }

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.HARDWARE_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData?.hardwareCost | currency:'USD':'symbol':'1.0'}}</strong>
    </div>
    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.INSTALLATION_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData?.installationCost | currency:'USD':'symbol':'1.0'}}</strong>
    </div>

    <div class="value" style="font-size: 16px;">{{
        (
        ((inputData?.hardwareCost      ?? 0) + (inputData?.installationCost ?? 0)) - ((inputData?.hasIncentives30c ? 100000 : 0) + (inputData?.incentives ?? 0))
        )
        | currency:'USD':'symbol':'1.0'
    }}</div>
  </div>

  <!-- ROI Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-dollar"></i>
      <div class="title">ROI for {{ inputData.numberOfPorts }} ports</div>
    </div>

    <div class="flex mt8">
      <div class="title">Suggested number of Ports</div>
      <strong>{{ inputData?.suggestedNumberOfPorts}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Avg Weekly Consumption</div>
      <strong>{{ inputData?.roiWeeklyEnergyConsumption | number : "1.0-0" }} kWh</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Revenue (Yearly)</div>
      <strong>{{inputData?.roiRevenue | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Profit (Yearly)</div>
      <strong>{{ inputData?.roiProfit | currency : "USD" : "symbol" : "1.0" }}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">NPV</div>
      <strong>{{inputData?.roiNpv | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">ROI in</div>
      <strong>{{ inputData.roiPeriod | transfromToYearMonthLabel }}</strong>
    </div>

  </div>

</div>
