import { Injectable         } from "@angular/core";
import { Observable         } from "rxjs";
import { SiteModel          } from "nv@models/site.model";
import { ApiResponseModel   ,
         HttpApiService     } from "nv@services/http.service";

@Injectable({
  providedIn: 'root'
})
export class SiteApi extends HttpApiService {

  /**
   * @author Mihail Petrov
   * Register new patient
   * @param $object
   * @returns
   */
  public getSite($id: string): Observable<ApiResponseModel>  {

    return this.forEndpoint(`application`, `sites`, $id)
               .fetch();
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public createNewSite($object: SiteModel): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `sites`)
               .withBody($object)
               .create();
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public importSite($object: any): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `sites`, `import`)
               .withBody($object)
               .create();
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public updateSite($object: SiteModel): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `sites`)
               .withBody($object)
               .update();
  }

  /**
   * @author Mihail Petrov
   * @param $id
   */
  public removeSite($id: string): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `sites`, $id)
               .remove();
  }

  public getOpenEiStatus(): Observable<ApiResponseModel> {
    return this.forEndpoint(`application`, `openei`, `status`)
               .fetch();
  }
}
