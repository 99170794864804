import {  CommonModule          } from "@angular/common";
import {  Component             ,
          OnChanges             ,
          SimpleChanges         } from "@angular/core";
import {  ReactiveFormsModule   } from "@angular/forms";
import {  SidebarModule         } from 'primeng/sidebar';
import {  DropdownModule        } from "primeng/dropdown";

import {  CcBaseSidebar         } from "nv@components/cc-sidebar/cc-sidebar.base";
import {  CcSidebar             } from "nv@components/cc-sidebar/cc-sidebar.component";
import {  TranslatePipe         } from "nv@services/translate.service";

@Component({
  selector    : 'manage-project-status',
  templateUrl : './manage-project-status.component.html',
  styleUrl    : './manage-project-status.component.scss',
  imports     : [
    ReactiveFormsModule ,
    CommonModule        ,
    SidebarModule       ,
    SidebarModule       ,
    CcSidebar           ,
    DropdownModule      ,
    TranslatePipe
  ]
})
export class SidebarManageProjectStatus extends CcBaseSidebar implements OnChanges {

  public sidebarTitle  = 'Manage Project status';
  public projectStatus!: string;

  public ngOnChanges(changes: SimpleChanges): void {
    this.projectStatus = this.inputData?.status;
  }

  public processForm() {
    this.inputData.status = this.projectStatus;
    this.returnToParent({
      ...this.inputData,
      status: this.projectStatus
    })
  }
}
