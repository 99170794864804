import {  Component             ,
          EventEmitter          ,
          Input                 ,
          OnInit                ,
          Output                } from "@angular/core";
import {  CommonModule          ,
          formatCurrency        } from "@angular/common";
import {  FormsModule           } from "@angular/forms";
import {  debounceTime          ,
          distinctUntilChanged  ,
          Subject               } from "rxjs";
import {  CcProjectLabel        } from "nv@components/cc-project-label/cc-project-label.component";
import {  TranslatePipe         } from "nv@services/translate.service";
import {  CcTableModel          } from "./cc-table.model";

@Component({
  selector    : 'cc-table',
  templateUrl : './cc-table.component.html',
  styleUrl    : './cc-table.component.scss',
  imports     : [
    CommonModule  ,
    FormsModule   ,
    TranslatePipe ,
    CcProjectLabel
  ]
})
export class CcTable implements OnInit {

  @Input() public inputHasPaging  = false;
  @Input() public inputTableMapping: CcTableModel[] = [];
  @Input() public inputDataSource: any
  @Input() public inputFilterByField: any

  @Input() public inputPage: number   = 0;
  @Input() public inputLimit: number  = 5;

  @Output() public onEdit     = new EventEmitter();
  @Output() public onDelete   = new EventEmitter();
  @Output() public onNavigate = new EventEmitter();
  @Output() public onSearch   = new EventEmitter();

  @Output() public onNext     = new EventEmitter();
  @Output() public onPrev     = new EventEmitter();

  public searchInput: any;
  public virtualDataSource: any

  public searchQuery$: Subject<string> = new Subject<string>();

  public uiState = {
    isEditVisible   : false,
    isDeleteVisible : false
  }

  /**
   * @author Mihail Petrov
   */
  public processSearch() {
    this.searchQuery$.next(this.searchInput);
  }

  /**
   * @author Mihail Petrov
   */
  public ngOnChanges(): void {
    this.virtualDataSource   = structuredClone(this.inputDataSource);
  }

  /**
   * @author Mihail Petrov
   */
  public ngOnInit(): void {

    if(this.onEdit.observed   ) this.uiState.isEditVisible    = true;
    if(this.onDelete.observed ) this.uiState.isDeleteVisible  = true;

    this.searchQuery$.pipe(debounceTime(400), distinctUntilChanged()).subscribe((model) => {
      this.onSearch.emit(model);
    });
  }

  /**
   * @author Mihail Petrov
   * @param $element
   */
  public processOnEdit($element: any): void {
    this.onEdit.emit($element)
  }

  /**
   * @author Mihail Petrov
   * @param $element
   */
  public processOnDelete($element: any): void {
    this.onDelete.emit($element)
  }

  /**
   * @author Mihail Petrov
   * @param $element
   */
  public processOnNavigate($element: any, modelObject: any): void{

    if(modelObject.isNavigatable) {
      this.onNavigate.emit($element)
    }
  }

  /**
   * @author Mihail Petrov
   * @param dataSource
   * @param modelObject
   * @returns
   */
  public getValue(dataSource: any, modelObject: any): any {

    if(modelObject?.value) {

      const keyCollection = (modelObject.value).split('.');
      let valueAccessor   = dataSource;

      for(const keyElement of keyCollection) {

        if(valueAccessor[keyElement] == null) {
          return '@NV@';
        }

        valueAccessor = valueAccessor[keyElement];
      }

      return valueAccessor;
    }

    if(modelObject?.fn) {
      return modelObject.fn(dataSource);
    }

    return "-";
  }

  public getClassIdByFn(dataSource: any, modelObject: any): any {

    if(modelObject?.classIdFn) {
      return modelObject.classIdFn(dataSource);
    }
  }


  /**
   * @author Mihail Petrov
   * @param dataSource
   * @param modelObject
   * @returns
   */
  public renderColumn(dataSource: any, modelObject: any): any {

    const getValue = this.getValue(dataSource, modelObject);

    if(getValue == '@NV@') {
      return '-';
    }

    if(modelObject?.type == 'money') {
      return formatCurrency(getValue, 'en-US', '$', 'USD', '1.0-0')
    }

    if(modelObject?.type == 'date') {

      return new Date(getValue).toLocaleDateString('en-US', {
        year: 'numeric', month: 'short', day: 'numeric'
      });
    }

    return getValue;
  }

  /**
   * @author Mihail Petrov
   */
  public processOnNext(): void {

    const index = ++this.inputPage;

    this.onNext.emit({
      page  : index * this.inputLimit,
      limit : this.inputLimit
    });
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public processOnPrev() {

    if(this.inputPage == 0) {
      return;
    }

    const index = --this.inputPage;

    this.onNext.emit({
      page  : index * this.inputLimit,
      limit : this.inputLimit
    });
  }
}
