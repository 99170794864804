{
  "@@DASHBOARD": {
    "PAGE": {
      "TITLE": "Customers"
    },
    "TABLE": {
      "CUSTOMER_NAME": "Customer Name",
      "NUMBER_OF_PROJECTS": "Number of Projects"
    },
    "NO_DATA_FOUND": {
      "TITLE": "We don't have any customers yet :(",
      "SUBTITLE": "Let's add information about our first customer"
    },
    "ACTION": {
      "CREATE": "Add New",
      "IMPORT": "Import"
    }
  },
  "@@CUSTOMER": {
    "PAGE": {
      "TAB_PROJECTS": "Projects",
      "TAB_CONTACTS": "Contacts"
    },
    "TABLE": {
      "PROJECT_NAME": "Project Name",
      "CONTACTS": "Primary Contact",
      "STATUS": "Status",
      "EXPECTED_ROI": "Expected ROI period",
      "SUGGESTED_PORTS" : "Provided number of Ports",
      "CAPITAL_EXPENSE" : "Capital Expense",
      "OVERALL_COSTS"   : "Operating Expense (1 year)",
      "N_SITES"         : "Number of Sites",
      "DEPLOYMENT"      : "Deployment",
      "FULL_NAME"       : "Full Name",
      "ROLE"            : "Role",
      "PHONE"           : "Phone",
      "EMAIL"           : "E-mail"
    },
    "NO_DATA_FOUND": {
      "PROJECT_TITLE": "No Projects Found",
      "PROJECT_SUBTITLE": "This customer has no active projects",
      "CONTACT_TITLE": "No Contacts Found :(",
      "CONTACT_SUBTITLE": "Contacts are the people involved in this project"
    },
    "ACTION": {
      "IMPORT"        : "Import",
      "PROJECT_CREATE": "Add New",
      "PROJECT_IMPORT": "Import",
      "CONTACT_CREATE": "Add New",
      "CONTACT_IMPORT": "Import"
    }
  },
  "@@PROJECT": {
    "PAGE": {
      "TITLE": "Sites"
    },
    "TEMPLATE": {
      "SUMMARY": {
        "NUMBER_OF_SITES": "Total Number of Sites",
        "NUMBER_OF_SCORED_SITES": "Number of Scored Sites",

        "FIRST_DEPLOYMENT": "First Deployment",
        "EXPECTED_ROI": "Expected ROI",
        "CAPITAL_EXPENSE": "Capital Expense",
        "OPERATING_EXPENSE": "Operating Expense (1 year)",
        "TOTAL_EXPENCE": "Capital Expense breakdown",

        "HARDWARE_COST": "Hardware",
        "INSTALLATION_COST": "Installation",
        "OPERATION_COST": "Operation",
        "INCENTIVES": "Federal/State Incentives",
        "INCENTIVE30C": "30C Incentives",
        "INCENTIVEBABA": "BABA Act",

        "PORTS": "Provided number of Ports",
        "ENERGY": "Weekly Energy Consumption"
      },
      "PROJECT_MAP": {
        "CONTACTS": "Contacts",
        "NO_DATA_FOUND": "No contacts",
        "NO_DATA_FOUND_SUB": "You can assign contacts for this project"
      }
    },
    "TABLE": {
      "SITE_NAME": "Site Name",
      "ADDRESS": "Address",
      "PORTS": "Provided number of Ports",
      "OVERALL_COST": "Overall Costs",
      "EXPECTED_ROI": "Expected ROI",
      "DEPLOYMENT": "Deployment",
      "CAPITAL_EXPENSE": "Capital Expense",
      "OPERATING_EXPENSE": "Operating Expense (1 year)",
      "ON_NEVI": "NEVI Eligible"
    },
    "NO_DATA_FOUND": {
      "TITLE": "This Project has no sites",
      "SUBTITLE": "Let's get ready for scoring. Add a new site to the project or do a batch import from a file."
    },
    "ACTION": {
      "CREATE": "Add New Site",
      "IMPORT": "Import Sites",
      "DOWNLOAD_REPORT": "Download Report",
      "CLOSE": "Manage Status"
    }
  },
  "@@SITE": {
    "PAGE": {
      "TITLE": "Add New Site"
    },
    "NO_DATA_FOUND": {},
    "TEMPLATE": {

      "DEMAND_SITE_COST" : {
        "NAME": "Demand calculation"
      },

      "ELECTRICITY_PRICE": {
        "NAME": "Electricity Price"
      },
      "PRICING_DETAILS": {
        "NAME": "Pricing Details"
      },

      "NUMBER_OF_PORTS": {
        "NAME": "Provided number of ports",
        "NUMBER_OF_PORTS": "Provided number of ports"
      },

      "ROI": {
        "NAME": "Return on Investment",
        "NON_RECOVERABLE": "This investment will take more than 10 years to recover",
        "NON_RECOVERABLE_SUB": "You can try again with different parameters",
        "EXPECTED_VISITORS": "Expected Weekly Visitors",
        "EXPECTED_CONSUMPTION": "Expected Weekly Consumption",
        "EXPECTED_NPV": "Expected 10 years NPV",
        "ROI_PERIOD_IS": "Expected ROI in ",
        "SUGGESTED_PORTS": "Suggested number of ports"
      },
      "SITE_LOCATION": {
        "NAME": "Site Location"
      },
      "UTILITY_SERVICE": {
        "NAME": "Utility Service Details"
      }
    },
    "WIZZARD": {
      "DETAILS": {
        "ADDRESS_TITLE": "Address and location",
        "SITE_NAME": "Site Name",
        "SITE_NAME_PLACEHOLDER": "Meaningful name for the site",
        "PROJECT_START_DATE": "Project Start Date",
        "PROJECT_START_DATE_PLACEHOLDER": "Anticipated start date",
        "SITE_LOCATION": "Site Location",
        "STATE": "State",
        "STATE_PLACEHOLDER": "Select the state",
        "CITY": "City",
        "CITY_PLACEHOLDER": "Select the city",
        "ZIP": "ZIP Code",
        "ZIP_PLACEHOLDER": "ZIP Code",
        "ADDRESS": "Address",
        "ADDRESS_PLACEHOLDER": "Address",
        "SITE_LOCATION_INFO": "Your site is located here"
      },
      "CONFIG": {
        "HARDWARE_CONFIGURATION": "Hardware Configuration",
        "HARDWARE_CONFIGURATION_PLACEHOLDER": "Select preferred type",
        "NUMBER_OF_UNITS": "Number of Units",
        "NUMBER_OF_PORTS": "Provided number of Ports",
        "UTILITY_PROVIDER_AND_PLAN": "Utility Provider and Plan",
        "UTILITY": "Utility",
        "UTILITY_PLACEHOLDER": "Select preferred type",
        "UTILITY_GROUP": "Utility Group",
        "UTILITY_GROUP_PLACEHOLDER": "Select preferred group",
        "UTILITY_PLAN": "Utility Plan",
        "UTILITY_PLAN_PLACEHOLDER": "Select preferred type",
        "PRICING_DETAILS": "Pricing Details",
        "MONTHLY_SUBSCRIPTION": "Monthly Subscription",
        "DRIVER_PRICE_MULTIPLIER": "Sales Price Multiplier",
        "INVESTMENT_DETAILS": "Investment Details",
        "UTILIZATION": "Utilization",
        "INCENTIVES": "Federal/State Incentives",
        "HARDWARE_COST": "Hardware Cost",
        "INSTALLATION_COST": "Installation Cost",
        "OPERATING_COST_PER_MONTH": "Operating Cost per Month",

        "MANUAL_CONFIG" : "The Hardware and Installation cost are manualy fixed. The hardware configuration will not affect the value. You can unlock it by clicking on the Unlock button"
      }
    },
    "ACTION": {
      "WIZZARD_SITE_DETAILS": "Site Details",
      "WIZZARD_SITE_CONFIG": "Site Configuration",
      "WIZZARD_DOWNLOAD_REPORT": "Download Report",
      "WIZZARD_GO_BACK": "Go Back",
      "WIZZARD_SAVE": "Save Site",
      "WIZZARD_CONTINUE": "Continue",
      "WIZZARD_SCORE": "Score"
    }
  },
  "@@404": {
    "PAGE": {
      "TITLE": "I Think We're Lost",
      "SUBTITLE": "Yup... we are on the wrong page. Let's go"
    }
  },

  "@@MANAGE_IMPORT": {

    "MESSAGE" : "The import is going to use the data inside the template. Do not change the names of the HEADER columns.",

    "ACTION" : {
      "TEMPLATE" : "Download import template"
    }
  },

  "@@MANAGE_CUSTOMERS": {
    "PAGE": {
      "CREATE_CUSTOMER_TITLE"     : "Add New Customer",
      "EDIT_CUSTOMER_TITLE"       : "Edit Customer",
      "REMOVE_CUSTOMER_TITLE"     : "Remove Customer",
      "IMPORT_CUSTOMER_TITLE"     : "Import Customer",
      "CUSTOMER_NAME"             : "Customer Name",
      "CUSTOMER_NAME_PLACEHOLDER" : "Enter customer name"
    },
    "ACTION": {
      "CREATE": "Save",
      "REMOVE": "Delete Customer",
      "CANCEL": "Cancel"
    }
  },
  "@@MANAGE_CONTACTS": {
    "PAGE": {
      "NAME_CREATE": "Add New Contact",
      "NAME_EDIT": "Edit Contact",
      "NAME_REMOVE": "Remove Contact",
      "FIRST_NAME": "First Name",
      "LAST_NAME": "Last Name",
      "ROLE": "Role",
      "PHONE": "Phone",
      "EMAIL": "E-mail",
      "REMOVE_MESSAGE": "isn't assigned to any projects. <br>Are you sure you want to delete this contact? This action cannot be undone, and all data associated with this contact will be permanently removed."
    },
    "ACTION": {
      "CREATE": "Save",
      "EDIT": "Save",
      "REMOVE": "Remove Contact",
      "CANCEL": "Cancel"
    }
  },
  "@@MANAGE_PROJECTS": {
    "PAGE": {
      "NAME_CREATE": "Add New Project",
      "NAME_EDIT": "Edit Project",
      "NAME_REMOVE": "Remove Project",
      "NAME": "Project Name",
      "CONTACT_LIST": "Project Contacts",
      "NO_CONTACT_FOR_PROJECT": "This customer does not have any contacts. You can create the project and add contacts later, or you can create a new contact from the Contacts tab."
    },
    "ACTION": {
      "ADD_TO_PROJECT": "Attach",
      "REMOVE": "Remove",
      "CREATE": "Save",
      "EDIT": "Save",
      "MANAGE_CONTACTS": "Manage Contacts",
      "CONTACT_CREATE": " Create contact",
      "ATTACH_CONTACT": " Attach contacts"
    }
  },
  "@@MANAGE_REPORTS": {
    "NO_DATA_FOUND": {
      "TITLE": "No Reports Found",
      "SUBTITLE": "Reports are generated only for scored sites"
    }
  },
  "@@MANAGE_SITES": {
    "NO_DATA_FOUND": {
      "TITLE": "You are about to remove",
      "SUBTITLE": "from the project. Are you sure?"
    },
    "ACTION": {
      "CANCEL": "Cancel",
      "REMOVE": "Remove Site"
    }
  },

  "@@CC_FORM_ERROR": {
    "REQUIRED": "The field is required",
    "PATTER_EMAIL": "Please enter a valid email address.",
    "PATTER_ZIP": "Please enter a valid ZIP code with exactly 5 digits."
  },


  "@@CC_TABLE" : {
    "NO_DATA_FOUND" : "Sorry .... there is no data for this query :("
  }
}
