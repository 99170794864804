import {  Component     ,
          EventEmitter  ,
          Input         ,
          Output        } from "@angular/core";
import {  TranslatePipe } from "nv@services/translate.service";

@Component({
  selector    : 'template-delete',
  templateUrl : './template-delete.component.html',
  imports     : [TranslatePipe]
})
export class TemplateDelete {

  @Input() public inputData: any;

  @Output() public onCancel = new EventEmitter();
  @Output() public onDelete = new EventEmitter();
}
