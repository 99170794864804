<div class="mt32">
    <div style="position: relative; border: solid #dedede 1px; border-radius: 4px;">
        <div style="width: 350px; height: 100%; background: #fff; position: absolute; top: 0; left: 0; z-index: 1100; padding: 16px;">

            <div class="flex" style="align-items: center;">
                <div style="font-weight: bold; font-size: 22px;">Competition</div>
            </div>

            @if(inputData.competition != null && inputData.competition.chargersPerLevel != null){
                @for (level of inputData.competition.chargersPerLevel; track $index){
                    <div style="overflow: auto;">
                        <div class="flex contact-element pt8">
                            <div class="flex" style="align-items: center;">
                                <div 
                                [ngStyle]="{
                                    'width': '12px',
                                    'height': '12px',
                                    'border-radius': '50%',
                                    'margin-right': '8px',
                                    'background-color': getColorForLevel(level.level)
                                }">
                                </div>

                                <div  style="font-weight: bold;">{{level.level}}</div>
                            </div>

                            <div class="flex" style="align-items: center;">
                                <p>
                                    {{level.count}}
                                </p>
                            </div>
                        </div>
                    </div>
                }
            }
        </div>

        @if(getCompetitionMarkers() != null && getCompetitionMarkers().length > 0){
            <cc-map
                [markerLocations]         = "getCompetitionMarkers()"
                [inputReferencedObjects]  = "[inputData]">
            </cc-map>
        }
    </div>
</div>
