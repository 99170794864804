<div class="no-data-found">

  <div>
    <i class="pi pi-bolt fs46"></i>
  </div>

  <div class="fsb32 mt8">
    {{'@@PROJECT.NO_DATA_FOUND.TITLE' | translate }}
  </div>

  <div class="fs16 mt16">
    {{'@@PROJECT.NO_DATA_FOUND.SUBTITLE' | translate }}
  </div>

  <div class="mt32">
    <div>
      <div (click)="onAdd.emit()" class="btn btn-primary">
        <i class="pi pi-plus"></i> {{'@@PROJECT.ACTION.CREATE' | translate }}
      </div>

      <div (click)="onImport.emit()" class="btn btn-plane ml32">
        <i class="pi pi-upload"></i> {{'@@PROJECT.ACTION.IMPORT' | translate }}
      </div>
    </div>
  </div>
</div>
