import { Component             ,
         inject                } from "@angular/core";
import { FormControl           ,
         ReactiveFormsModule   ,
         Validators            } from "@angular/forms";
import { CommonModule          } from "@angular/common";
import { SidebarModule         } from 'primeng/sidebar';

import { ContactModel          } from "nv@models/contact.model";
import { CcFormGroup           } from "app/@shared/forms/form-group/cc-form-group.extention";
import { CcBaseSidebar         } from "nv@components/cc-sidebar/cc-sidebar.base";
import { CcSidebar             } from "nv@components/cc-sidebar/cc-sidebar.component";
import { TranslateService      } from "nv@services/translate.service";
import { ActionEnterDirective  } from "app/@shared/directives/action-enter.directive";
import { TemplateDelete        } from "./@templates/template-delete/template-delete.component";
import { TemplateCreate        } from "./@templates/template-create/template-create.component";
import { TemplateEdit          } from "./@templates/template-edit/template-edit.component";
import { TemplateImport        } from "./@templates/template-import/template-import.component";

@Component({
  selector    : 'sidebar-manage-projects',
  templateUrl : './manage-projects.component.html',
  styleUrl    : './manage-projects.component.scss',
  imports     : [
    ReactiveFormsModule ,
    SidebarModule       ,
    SidebarModule       ,
    CcSidebar           ,
    CommonModule        ,
    ActionEnterDirective,

    TemplateDelete      ,
    TemplateCreate      ,
    TemplateEdit        ,
    TemplateImport
  ]
})
export class SidebarManageProjects extends CcBaseSidebar {

  private ts                      = inject(TranslateService);
  public sidebarTitle             = this.ts.translate('@@MANAGE_PROJECTS.PAGE.NAME_CREATE');
  public projectContactList: any  = [];
  public contactCollection: any   = [];

  public $form = new CcFormGroup({
    name : new FormControl(null, [Validators.required]),
  });

  public ngOnChanges() {

    this.projectContactList = [];

    if(this.isInCreateMode()) {
      this.$form.reset();
      this.projectContactList   = this.inputData.contactList;
    }

    if(this.isInEditMode()) {

      this.sidebarTitle = this.ts.translate('@@MANAGE_PROJECTS.PAGE.NAME_EDIT');
      this.$form.controls['name'  ].setValue(this.inputData?.transactionObject?.name);

      const customerContactList     = this.inputData.contactList;
      const projectContactList      = this.inputData.transactionObject?.contacts;

      for(const element of customerContactList) {

        const matchContact = projectContactList?.find((el: any) => el.id == element.id);

        if(matchContact) {
          this.projectContactList?.push({...element, isSelected: true, isPrimary: matchContact.isPrimary })
        }
        else {
          this.projectContactList?.push({...element, isSelected: false, isPrimary: false })
        }
      }

      this.projectContactList?.sort((a: any, b: any) => {
        return (a.isSelected === b.isSelected) ? 0 : a.isSelected ? -1 : 1;
      })
    }

    if(this.isInDeleteMode()) {
      this.sidebarTitle = this.ts.translate('@@MANAGE_PROJECTS.PAGE.NAME_REMOVE');
    }
  }

  /**
   * @author Mihail Petrov
   * @param element
   */
  public addContact(element: any) {
    this.contactCollection.push(element)
  }

  /**
   * @author Mihail Petrov
   */
  public async processForm() {

    this.$form.markFormGroupDirty().isFormValid(() => {

      const selectedContactCollection = this.projectContactList.filter((element: any) => {
        return element.isSelected == true;
      });

      if(this.isInCreateMode()) {

        this.returnToParent({
          contacts: selectedContactCollection,
          ...this.$form.value
        });
      }

      if(this.isInEditMode()) {
        this.inputData.transactionObject.contacts = selectedContactCollection;

        this.returnToParent({
          ...this.inputData.transactionObject,
          ...this.$form.value
        });
      }
    });
  }

  /**
   * @author Mihail Petrov
   */
  public removeEntity() {
    this.returnToParent(this.inputData.transactionObject);
  }

  /**
   * @author Mihail Petrov
   * @param contact
   */
  public addContactToProject(contact: any) {
    contact.isSelected = true;
  }

  /**
   * @author Mihail Petrov
   * @param contact
   */
  public removeContactFromProject(contact: any) {
    contact.isSelected = false;
  }

  /**
   * @author Mihail Petrov
   * @param contact
   */
  public makeItPrimary(contact: ContactModel) {

    this.projectContactList = this.projectContactList.map((element: ContactModel) => {
      return { ...element, isPrimary: (contact.id == element.id) }
    });
  }

  /**
   * @author Mihail Petrov
   * @param event
   */
  public processFile(event: any) {

    const file: File  = event.files[0];
    const formData    = new FormData();
    formData.append('file', file, file.name);

    this.returnToParent(formData);
  }
}
