import {  Component           ,
          EventEmitter        ,
          Input               ,
          Output              } from "@angular/core";
import {  ReactiveFormsModule } from "@angular/forms";
import {  SidebarModule       } from 'primeng/sidebar';
import {  FileUploadModule    } from 'primeng/fileupload';

import {  TranslatePipe       } from "nv@services/translate.service";

@Component({
  selector    : 'template-import',
  templateUrl : './template-import.component.html',
  imports     : [
    ReactiveFormsModule ,
    SidebarModule       ,
    SidebarModule       ,
    TranslatePipe       ,
    FileUploadModule
  ]
})
export class TemplateImport {

  @Input() public $form: any;
  @Output() public onUpload = new EventEmitter();

  public downloadTemplate() {
    window.location.href='/assets/import_templates/import_projects.xlsx';
  }
}
