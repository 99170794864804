import {  Component             ,
          EventEmitter          ,
          Input                 ,
          OnInit                ,
          Output                } from "@angular/core";
import {  CommonModule          ,
          formatCurrency        } from "@angular/common";
import {  FormsModule           } from "@angular/forms";
import {  debounceTime          ,
          distinctUntilChanged  ,
          Subject               } from "rxjs";
import {  CcProjectLabel        } from "nv@components/cc-project-label/cc-project-label.component";
import {  TranslatePipe         } from "nv@services/translate.service";
import {  CcTableModel          } from "./cc-table.model";

@Component({
  selector    : 'cc-table',
  templateUrl : './cc-table.component.html',
  styleUrl    : './cc-table.component.scss',
  imports     : [
    CommonModule  ,
    FormsModule   ,
    TranslatePipe ,
    CcProjectLabel
  ]
})
export class CcTable implements OnInit {

  @Input() public inputHasPaging  = false;
  @Input() public inputTableMapping: CcTableModel[] = [];
  @Input() public inputDataSource: any
  @Input() public inputFilterByField: any

  @Input() public inputPage: number   = 0;
  @Input() public inputLimit: number  = 5;

  @Output() public onEdit     = new EventEmitter();
  @Output() public onDelete   = new EventEmitter();
  @Output() public onNavigate = new EventEmitter();
  @Output() public onSearch = new EventEmitter();
  
  tooltipData: { title: string, value: number }[] | null = null;
  tooltipPosition: { top: string, left: string } = { top: '0px', left: '0px' };

  @Output() public onNext     = new EventEmitter();
  @Output() public onPrev     = new EventEmitter();

  public searchInput: any;
  public virtualDataSource: any

  public searchQuery$: Subject<string> = new Subject<string>();

  public uiState = {
    isEditVisible   : false,
    isDeleteVisible : false
  }

  /**
   * @author Mihail Petrov
   */
  public processSearch() {
    this.searchQuery$.next(this.searchInput);
  }

  /**
   * @author Mihail Petrov
   */
  public ngOnChanges(): void {
    this.virtualDataSource   = structuredClone(this.inputDataSource);
  }

  /**
   * @author Mihail Petrov
   */
  public ngOnInit(): void {

    if(this.onEdit.observed   ) this.uiState.isEditVisible    = true;
    if(this.onDelete.observed ) this.uiState.isDeleteVisible  = true;

    this.searchQuery$.pipe(debounceTime(400), distinctUntilChanged()).subscribe((model) => {
      this.onSearch.emit(model);
    });
  }

  /**
   * @author Mihail Petrov
   * @param $element
   */
  public processOnEdit($element: any): void {
    this.onEdit.emit($element)
  }

  /**
   * @author Mihail Petrov
   * @param $element
   */
  public processOnDelete($element: any): void {
    this.onDelete.emit($element)
  }

  /**
   * @author Mihail Petrov
   * @param $element
   */
  public processOnNavigate($element: any, modelObject: any): void{

    if(modelObject.isNavigatable) {
      this.onNavigate.emit($element)
    }
  }

  /**
   * @author Mihail Petrov
   * @param dataSource
   * @param modelObject
   * @returns
   */
  public getValue(dataSource: any, modelObject: any): any {

    if(modelObject?.value) {

      const keyCollection = (modelObject.value).split('.');
      let valueAccessor   = dataSource;

      for(const keyElement of keyCollection) {

        if(valueAccessor[keyElement] == null) {
          return '@NV@';
        }

        valueAccessor = valueAccessor[keyElement];
      }

      return valueAccessor;
    }

    if(modelObject?.fn) {
      return modelObject.fn(dataSource);
    }

    return "-";
  }

  public getClassIdByFn(dataSource: any, modelObject: any): any {

    if(modelObject?.classIdFn) {
      return modelObject.classIdFn(dataSource);
    }
  }


  /**
   * @author Mihail Petrov
   * @param dataSource
   * @param modelObject
   * @returns
   */
  public renderColumn(dataSource: any, modelObject: any): any {

    const getValue = this.getValue(dataSource, modelObject);

    if(getValue == '@NV@') {
      return '-';
    }

    if(modelObject?.type == 'money') {
      return formatCurrency(getValue, 'en-US', '$', 'USD', '1.0-0')
    }

    if(modelObject?.type == 'date') {

      return new Date(getValue).toLocaleDateString('en-US', {
        year: 'numeric', month: 'short', day: 'numeric'
      });
    }

    return getValue;
  }

  /**
   * @author Mihail Petrov
   */
  public processOnNext(): void {

    const index = ++this.inputPage;

    this.onNext.emit({
      page  : index * this.inputLimit,
      limit : this.inputLimit
    });
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public processOnPrev() {

    if(this.inputPage == 0) {
      return;
    }

    const index = --this.inputPage;

    this.onNext.emit({
      page  : index * this.inputLimit,
      limit : this.inputLimit
    });
  }

  /**
   * @author Samuil Dobrinski
   * @returns
   */
  public showTooltip(event: MouseEvent, data: { title: string, value: number }[] | null) {
    if (!data) return;

    const targetElement = event.target as HTMLElement;
    const rect = targetElement.getBoundingClientRect(); // Get position relative to viewport

    this.tooltipData = data;

    this.tooltipPosition = {
        top: `${rect.top + window.scrollY - 150}px`,
        left: `${rect.left + window.scrollX - 70}px` // Align left with the cell
    };
}

  /**
   * @author Samuil Dobrinski
   * @returns
   */
  public hideTooltip() {
    this.tooltipData = null;
  }

  public getMaxValue(index: number): number {
    const maxValues = [25, 20, 55];
    return maxValues[index] ?? 100;
  }

  /**
   * @author Samuil Dobrinski
   * @returns
   */
  public getBarColor(index: number): string {
      const colors = ["#C6213E", "#6D9953", "#22689A", "#D3F26A"];
      return colors[index] || "#CCCCCC"; // Default color if index is out of range
  }

  public getDisplayItems(): { title: string; value: number }[] {
    // if tooltipData is null or has fewer than 4 items, handle gracefully
    if (!this.tooltipData || this.tooltipData.length < 4) {
      return this.tooltipData ?? [];
    }

    const a = this.tooltipData[0]; // item #1
    const b = this.tooltipData[1]; // item #2
    const c = this.tooltipData[2]; // item #3
    const d = this.tooltipData[3]; // item #4

    // The 3rd row merges c and d
    const cPlusD = {
      // e.g. use c's title or a custom combined title
      title: c.title,
      value: c.value + d.value
    };

    // Return a 3-item array
    return [a, b, cPlusD];
  }
}
