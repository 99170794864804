import { Injectable } from "@angular/core";
import { ProjectModel } from "nv@models/project.model";
import { ApiResponseModel, HttpApiService } from "nv@services/http.service";
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ProjectApi extends HttpApiService  {

  /**
   * @author Mihail Petrov
   * Register new patient
   * @param $object
   * @returns
   */
  public getProject($id: string): Observable<ApiResponseModel>  {

    return this.forEndpoint(`application`, `projects`, $id)
               .fetch();
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public createNewProject($object: ProjectModel): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `projects`)
               .withBody($object)
               .create();
  }

  /**
   * @author Mihail Petrov
   * @param $object
   * @returns
   */
  public importProject($object: any): Observable<ApiResponseModel> {

    return this.forEndpoint(`application`, `projects`, `import`)
               .withBody($object)
               .create();
  }


  /**
   * @author Mihail Petrov
   * @param $id
   * @returns
   */
  public updateProject($entity: ProjectModel): Observable<ApiResponseModel> {

    return this.forEndpoint('application', `projects`)
           .withBody($entity)
           .update();
  }

  /**
   * @author Mihail Petrov
   * @param $id
   * @returns
   */
  public removeProject($id: string): Observable<ApiResponseModel> {

    return this.forEndpoint('application', `projects`, $id)
           .remove()
  }

  /**
   * @author Mihail Petrov
   * @param $id
   * @returns
   */
  public getAllSitesForProject($id: string, $meta?: any): Observable<ApiResponseModel>  {

    return this.forEndpoint     (`application`, `projects`, $id, `sites`)
               .withQuery       ("page"   , $meta?.page || 0  )
               .withQuery       ("limit"  , $meta?.limit || 5 )
               .withFilterQuery ("name"   , $meta?.name)
               .fetch           ();
  }
}
