import {  CommonModule                  } from '@angular/common';
import {  Component                     ,
          inject,
          Input                         } from '@angular/core';
import { environment } from 'environments/environment';
import { ReportApi } from 'nv@api/report.api.service';
import { TransfromToYearMonthLabelPipe  } from 'nv@pipes/year-month-label.pipe';
import {  TranslatePipe                 } from 'nv@services/translate.service';

@Component({
  selector    : 'template-roi',
  templateUrl : './template-roi.component.html',
  imports: [
    CommonModule                  ,
    TranslatePipe                 ,
    TransfromToYearMonthLabelPipe
  ]
})
export class TemplateRoi {

  environment = environment;
  private $reportApi  = inject(ReportApi);

  @Input() public inputData: any;
  @Input() public inputIsVisible = false
  @Input() public inputIsVisibleSuggested = false

  /**
   * @author Mihail Petrov
   */
  public processDownloadReport() {


    const a = {
      "planName"                : this.inputData.utilityPlan,
      "planSector"              : this.inputData.utilityPlanGroup,
      "utilityPlanId"           : this.inputData.utilityPlanId,

      "projectStartDate"        : new Date(this.inputData.anticipatedStartDate)?.toISOString()?.slice(0, 10),

      "latitude"                : this.inputData.latLong.latitude,
      "longitude"               : this.inputData.latLong.longitude,
      "state"                   : this.inputData.state,

      "operatingCost"           : this.inputData.operatingCost as number,
      "incentives"              : this.inputData.incentives as number,
      "has30c"                  : this.inputData.hasIncentives30c,
      "hasBABA"                 : this.inputData.hasIncentivesBABA,

      "subscriptionFeePerMonth" : this.inputData.monthlySubscription,
      "monthlySoftwareCost"     : 315,

      "hardwareConfigCollection": this.transformHardwareConfigToCabinets(this.inputData.hardwareConfigCollection),
      "hardwareCost"            : this.inputData.hardwareCost,
      "installCost"             : this.inputData.installationCost,
      "totalSockets"            : this.inputData.numberOfPorts,

      "flatFeePerSession"       : 0,

      "utilityCost"             : this.inputData.utilityCost,

      "driverPriceMultiplier"   : this.inputData.driverPriceMultiplier,
      "zip"                     : this.inputData.zip,
      "kwhPerVisit"             : 25,

      "utilization"             : this.inputData.utilization
    }

    this.$reportApi.getExcelReport(a).subscribe((response) => {

      const blob  = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url   = window.URL.createObjectURL(blob);
      const a     = document.createElement('a');
      a.href      = url;
      a.download  = 'plan_report.xlsx'; // Replace with desired file name
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }


  private transformHardwareConfigToCabinets(hardwareConfigCollection: any) {

    // Initialize the cabinets array
    const cabinets: any = [];

    hardwareConfigCollection.forEach((config: any) => {
      console.log(config)
        const { numberOfUnits, numberOfPorts, hardwareCapacity } = config;

        // Create chargers array based on numberOfUnits
        const chargers = [];
        for (let i = 0; i < numberOfUnits; i++) {
            chargers.push({
                capacity  : hardwareCapacity || 0,
                ports     : numberOfPorts,
            });
        }

        // Add a cabinet with the chargers
        cabinets.push({
            capacity: 700,
            chargers: chargers,
        });
    });

    return cabinets;
}
}
