import {  Component           ,
          inject              } from "@angular/core";
import {  ReactiveFormsModule } from "@angular/forms";
import {  SidebarModule       } from 'primeng/sidebar';
import {  CcBaseSidebar       } from "nv@components/cc-sidebar/cc-sidebar.base";
import {  CcSidebar           } from "nv@components/cc-sidebar/cc-sidebar.component";
import {  TranslatePipe       ,
          TranslateService    } from "nv@services/translate.service";
import {  CommonModule        } from "@angular/common";
import {  ContactModel        } from "nv@models/contact.model";

@Component({
  selector    : 'sidebar-manage-project-contacts',
  templateUrl : './manage-project-contacts.component.html',
  styleUrl    : './manage-project-contacts.component.scss',
  imports     : [
    ReactiveFormsModule ,
    SidebarModule       ,
    SidebarModule       ,
    CcSidebar           ,
    TranslatePipe       ,
    CommonModule
  ]
})
export class SidebarManageProjectContacts extends CcBaseSidebar {

  private ts                      = inject(TranslateService);
  public sidebarTitle             = this.ts.translate('@@MANAGE_PROJECTS.PAGE.NAME_REMOVE');
  public projectContactList: any  = [];
  public contactCollection: any   = [];

  public ngOnChanges() {

    this.projectContactList = [];

    if(this.isInEditMode()) {

      this.sidebarTitle = 'Manage project contacts';

      const customerContactList     = this.inputData.contactList;
      const projectContactList      = this.inputData.transactionObject?.contacts;

      for(const element of customerContactList) {

        const matchContact = projectContactList?.find((el: any) => el.id == element.id);

        if(matchContact) {
          this.projectContactList?.push({...element, isSelected: true, isPrimary: matchContact.isPrimary })
        }
        else {
          this.projectContactList?.push({...element, isSelected: false, isPrimary: false })
        }
      }

      this.projectContactList?.sort((a: any, b: any) => {
        return (a.isSelected === b.isSelected) ? 0 : a.isSelected ? -1 : 1;
      })
    }

    if(this.isInDeleteMode()) {
      this.sidebarTitle = this.ts.translate('@@MANAGE_PROJECTS.PAGE.NAME_REMOVE');
    }
  }

  /**
   * @author Mihail Petrov
   * @param element
   */
  public addContact(element: any) {
    this.contactCollection.push(element)
  }

  /**
   * @author Mihail Petrov
   */
  public async processForm() {

    const selectedContactCollection = this.projectContactList.filter((element: any) => {
      return element.isSelected == true;
    });

    if(this.isInEditMode()) {
      this.inputData.transactionObject.contacts = selectedContactCollection;

      this.returnToParent({
        ...this.inputData.transactionObject
      });
    }
  }

  /**
   * @author Mihail Petrov
   */
  public removeEntity() {
    this.returnToParent(this.inputData.transactionObject);
  }

  /**
   * @author Mihail Petrov
   * @param contact
   */
  public addContactToProject(contact: any) {
    contact.isSelected = true;
  }

  /**
   * @author Mihail Petrov
   * @param contact
   */
  public removeContactFromProject(contact: any) {
    contact.isSelected = false;
  }

  /**
   * @author Mihail Petrov
   * @param contact
   */
  public makeItPrimary(contact: ContactModel) {

    this.projectContactList = this.projectContactList.map((element: ContactModel) => {
      return { ...element, isPrimary: (contact.id == element.id) }
    });
  }
}
