import { inject, Injectable             } from "@angular/core";
import { CcTableModel                   } from "nv@components/cc-table/cc-table.model";
import { TransfromToYearMonthLabelPipe  } from "nv@pipes/year-month-label.pipe";
import { TranslateService               } from "nv@services/translate.service";

@Injectable()
export class ProjectState {

  private ts = inject(TranslateService);

  public state = {
    isSidebarManageSitesVisible     : false,
    isSidebarManageReportsVisible   : false,
    isSidebarManageContactsVisible  : false,
    isSidebarManageProjectStatus    : false,
    isSidebarCreateContactVisible   : false
  }

  public data = {
    sidebarTransactionObject  : null,
    sidebarTransactionAction  : ''
  };

  public siteMapping: CcTableModel[]        = [

    { column: this.ts.translate('@@PROJECT.TABLE.SITE_NAME')        , value : 'data.name', isNavigatable: true                      },

    {
      column    : this.ts.translate('@@PROJECT.TABLE.SITE_SCORE')       ,
      fn        : (element: any) => element?.data?.score ? element?.data?.score : '-' ,
      classIdFn: (element: any) => this.getScoreClass(element?.data?.score),
      tooltipFn : (element: any) => [
        { title: "Market", value: element.market_potential_score },
        { title: "Infrastructure", value: element.charging_infrastructure_score },
        { title: "Financial", value: element.financial_utilization_performance_score },
        { title: "NEVI", value: element.nevi_score }
      ]
    },

    { column: this.ts.translate('@@PROJECT.TABLE.ADDRESS'           ), fn     : (el: any) => `${el.data.address}, ${el.data.city}`    },
    { column: this.ts.translate('@@PROJECT.TABLE.ON_NEVI'           ), fn     : (el: any) => (el.data.onNeviRoute) ? 'YES' : 'NO'     },
    { column: this.ts.translate('@@PROJECT.TABLE.PORTS'             ), value  : 'data.numberOfPorts'                                  },
    { column: this.ts.translate('@@PROJECT.TABLE.CAPITAL_EXPENSE'   ), value  : 'data.capitalExpenses'    , type: 'money'             },

    { column: this.ts.translate('@@PROJECT.TABLE.OPERATING_EXPENSE' ), value  : 'data.operatingExpenses'  , type: 'money'             },
    { column: this.ts.translate('@@PROJECT.TABLE.ROI_NPV'           ), value  : 'data.roiNpv'             , type: 'money'             },

    { column: this.ts.translate('@@PROJECT.TABLE.EXPECTED_ROI')               , classIdFn: (el: any) => this.getClass(el.status),
      fn    : (el: any) => {

        if(el.status == 'NOT_SCORED'            ) return `Not Scored`;
        if(el.status == 'SCORED_NOT_PROFITABLE' ) return `>10 Years`;
        return new TransfromToYearMonthLabelPipe().transform(el.data?.roiPeriod);
      }
    },

    { column: this.ts.translate('@@PROJECT.TABLE.DEPLOYMENT')       , value : 'data.anticipatedStartDate' , type: 'date'            },
  ];

  /**
   * @author Mihail Petrov
   * @param value
   * @returns
   */
  private getScoreClass(value: number): string {

    if(value > 50 ) return `c-success`;
    if(value > 0  ) return `c-fail`;
    return '';
  }

  /**
   * @author Mihail Petrov
   * @param status
   * @returns
   */
  private getClass(status: string): any {

    if(status == 'NOT_SCORED'             ) return `yellow`
    if(status == 'SCORED_NOT_PROFITABLE'  ) return `red`
    return ``;
  }
}
