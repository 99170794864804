import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector    : '[actionEnter]'
})
export class ActionEnterDirective {

  @Output() onEnter = new EventEmitter<void>();

  @HostListener('window:keydown.enter', ['$event'])
  onKeydown(event: KeyboardEvent): void {

    event.preventDefault();
    this.onEnter.emit();
  }
}
