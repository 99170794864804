export const environment = {
  production: true,

  serviceUrls: {
    application: 'https://konect-api-tst.azurewebsites.net/api/v1',
    roi: 'https://konect-flask-api-tst.azurewebsites.net/api/v1',
  },

  msalConfig: {
    auth: {
      clientId: 'caa8a58a-fe96-4724-964d-93865079abdb',
      authority:
        'https://login.microsoftonline.com/8df22a8d-9153-489d-950c-a20cccb65620',
      redirectUri: 'https://konect-uat.vontierdatadev.com',
    },
  },

  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
};
