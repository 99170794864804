import {  CommonModule  } from '@angular/common';
import {  Component     ,
          Input         } from '@angular/core';
import {  TranslatePipe } from 'nv@services/translate.service';

@Component({
  selector    : 'template-roi',
  templateUrl : './template-roi.component.html',
  imports     : [ CommonModule, TranslatePipe ]
})
export class TemplateRoi {

  @Input() public inputData: any;
  @Input() public inputIsVisible = false



}
